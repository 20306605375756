import Header from "components/Headers/Header";
import Button from "react-bootstrap/Button";
import { istConversion } from "functions/timeConversion";
import { findExpiryDate } from "functions/timeConversion";
import { getCouponsUsed } from "network/ApiAxios";
import { fetchStripePayments } from "network/ApiAxios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Card, CardHeader, Row, Table } from "reactstrap";
import { Modal } from "reactstrap";
import { TextField } from "@material-ui/core";
import { MdCancel } from "react-icons/md";
import { applyCoupoun } from "network/ApiAxios";
import customToast from "./ToastComponent";
import { findOrganizationDetails } from "network/ApiAxios";
import { ToastContainer } from "react-toastify";
import ValidatedInput from "functions/ValidatedInput";
import { ADMIN_ROUTE_PATHS } from "routes-constants";

const Payments = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const orgDetails = JSON.parse(localStorage.getItem("keys"));
  const [payment, setPayment] = useState([]);
  const [openCoupon, setOpenCoupon] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [load, setLoad] = useState(false);
  const [code, setCode] = useState("");
  const [isCouponValid, setIsCouponValid] = useState(true);
  const [couponErrorMessage, setCouponErrorMessage] = useState("");
  const toggleCoupon = () => {
    setOpenCoupon(!openCoupon);
    setCouponErrorMessage("");
    setCode("");
  };
  useEffect(() => {
    const f = async () => {
      setLoad(true);
      const { data } = await fetchStripePayments(user.email, user.orgToken);
      setLoad(false);
      setPayment(data.invoices);
      setLoad(true);
      const coupe = await getCouponsUsed(orgDetails._id);
      setLoad(false);
      setCoupons(coupe.subscriptionDetails);
      console.log(coupe, "coupe");
      console.log(data, "stripe data");
    };
    f();
  }, []);
  console.log(payment);

  const apply = async () => {
    const org_token = JSON.parse(localStorage.getItem("user"))?.orgToken;
    const { data } = await applyCoupoun(code, org_token);
    if (data.success) {
      // const newUser = await getUser();
      const newExp = await findOrganizationDetails(org_token);

      if (newExp.success === true) {
        localStorage.setItem(
          "expiryDate",
          JSON.stringify(newExp.msg.expiry_date)
        );
        const userLimit = localStorage.getItem("keys");
        const parsedObject = JSON.parse(userLimit);
        parsedObject.onBoardUserLimit = newExp.msg.onBoardUserLimit;
        const modifiedNdStringifiedForStorage = JSON.stringify(parsedObject);
        localStorage.setItem("keys", modifiedNdStringifiedForStorage);
      }
      toggleCoupon();
      customToast(
        true,
        "",
        "",
        `Enterprise coupon activated!`,
        "Your license has been activated, kindly re-login to take the effect.",
        "Go to Subscriptions",
        `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.SUBSCRIPTION}`,
        ""
      );
    } else {
      customToast(
        "",
        true,
        "",
        `Enterprise coupon failed!`,
        "Check the entered code and try again",
        "Go to Subscriptions",
        `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.SUBSCRIPTION}`,
        ""
      );
    }
  };

  const handleCouponChange = (e) => {
    const newCoupon = e.target.value;
    setCode(newCoupon);

    // Email validation rules
    const couponPattern = /^[A-Z0-9]{5}$/;
    const isValid = couponPattern.test(newCoupon);
    setIsCouponValid(isValid);
    setCouponErrorMessage(
      isValid ? "" : "Coupon format must be 5 characters which is alphanumeric."
    );
  };

  const handleCouponBlur = () => {
    // Email validation rules
    const couponPattern = /^[A-Z0-9]{5}$/;

    setIsCouponValid(couponPattern.test(code));
    if (isCouponValid) {
      setCouponErrorMessage("");
    } else {
      setCouponErrorMessage(
        "Coupon format must be 5 characters which is alphanumeric."
      );
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <Modal isOpen={openCoupon} toggle={toggleCoupon} centered size="md">
        <Card
          style={{
            width: "600px",
            height: "250px",
            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            borderRadius: "15px",
            backgroundColor: "#F6F7F9",
          }}
          className="p-4"
        >
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h1 className="font-bold text-black">Apply Code</h1>
            <MdCancel
              color="#d3d3d3"
              className="font-bold cursor-pointer"
              size={20}
              onClick={toggleCoupon}
            />
          </div>

          <div className="d-flex flex-col">
            <h2 className="mb-4 text-black">Coupon or Activation Code</h2>
            <ValidatedInput
              label="Code"
              onChange={handleCouponChange}
              onBlur={handleCouponBlur}
              onFocus={() => setIsCouponValid(true)}
              value={code}
              isValid={isCouponValid}
              placeholder="Enter a coupon code or activation code"
              errorMessage={couponErrorMessage}
            />
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <Button
              style={{
                color: "black",
                backgroundColor: "#fff",
                borderColor: "black",
                borderRadius: "8px",
                outline: "none !important",
                outlineOffset: "none !important",
                boxShadow: "none",
              }}
              onClick={() => {
                toggleCoupon();
                setCode("");
              }}
            >
              Close
            </Button>
            <Button
              style={{
                color: "#fff",
                backgroundColor: "#47BE9B",
                borderColor: "#47BE9B",
                borderRadius: "8px",
                outline: "none !important",
                outlineOffset: "none !important",
                boxShadow: "none",
              }}
              disabled={!code || couponErrorMessage}
              onClick={() => apply()}
              className={`${!code}` ? "cursor-not-allowed" : ""}
            >
              Submit
            </Button>
          </div>
        </Card>
      </Modal>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "22px",
          marginTop: "22px",
        }}
      >
        <h6 style={{ color: "black" }}>History</h6>
        <p style={{ color: "grey", marginBottom: "5px" }}>
          For audit purpose, <b>MessageMe™ </b>stores the coupons used and
          completed payments
        </p>
        <div className="d-flex align-items-center justify-content-between">
          <h6 style={{ color: "black" }}>Organization Tokens</h6>
          <div className="pr-4">
            <Button
              style={{
                backgroundColor: "#3107CB",
                color: "#fff",
                borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
              }}
              className="d-flex align-items-center justify-content-center"
              onClick={toggleCoupon}
            >
              Apply Credit
            </Button>
          </div>
        </div>
      </div>

      {/* ORGANIZATION TOKEN HISTORY */}
      <div style={{ margin: "22px" }} className="text-black">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3>Tokens Redeemed</h3>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Coupon ID</th>
                    <th scope="col">Coupon</th>
                    <th scope="col">Number of Days</th>
                    <th scope="col">Number of Users</th>
                    <th scope="col">Issue Date</th>
                    <th scope="col">Redeemed Date</th>
                    {/* <th scope='col'></th> */}
                  </tr>
                </thead>
                <tbody>
                  {load === true ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ marginLeft: "325%" }}
                    >
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#3107cb"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    coupons.map((c) => (
                      <tr key={c.token}>
                        <td>{c.couponId}</td>
                        <td>{c.token}</td>
                        <td>{c.number_of_days}</td>
                        <td>{c.number_of_users}</td>
                        <td>{istConversion(c.issue_date)}</td>
                        <td>{istConversion(c.reedemed_date)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </div>

      {/* PAYMENTS HISTORY */}
      <div
        style={{ margin: "22px", marginBottom: "310px" }}
        className="text-black"
      >
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3>Payments</h3>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Payment ID</th>
                    <th scope="col">Plan Purchased</th>
                    <th scope="col">License Purchased/Removed</th>
                    <th scope="col">Licenses Present</th>
                    <th scope="col">Amount Paid</th>
                    <th scope="col">Date</th>
                    {/* <th scope='col'></th> */}
                  </tr>
                </thead>
                <tbody>
                  {load === true ? (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ marginLeft: "347%" }}
                    >
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#3107cb"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    payment?.map((p) => (
                      <tr key={p.paymentIntentId}>
                        <td>{p.paymentIntentId}</td>
                        <td>
                          {p.planPurchased.charAt(0).toUpperCase() +
                            p.planPurchased.slice(1)}
                        </td>
                        <td>{p.newUsersOnboarded}</td>
                        <td>{p.onBoardUserLimit}</td>
                        <td>{p.subscriptionAmountPaid}</td>
                        <td>{istConversion(p.date)}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </div>
    </>
  );
};

export default Payments;
