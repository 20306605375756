import React, { useEffect } from 'react';
import { Card, CardBody, Col } from "reactstrap";
import { AUTH_ROUTE_PATHS } from 'routes-constants';

const ResetPasswordSuccess = props => {

    useEffect(() => {
        setTimeout(() => {
            props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`);
        }, 5000);
    }, [])

    return (
        <>
            <Col lg="6" md="8">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center mb-4 text-white">
                            <h1>Password reset confirmed! You will be redirected to login...</h1>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    )
};

export default ResetPasswordSuccess;
