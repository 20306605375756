const path = require("path");
require("dotenv").config({ path: path.resolve("front-end/.env") });

let config = {};
let status = process.env.REACT_APP_NODE_ENV;
// const recaptchaToken = process.env.REACT_APP_CAPTCHA_SITE_KEY_V3;
if (status === "LOCAL") {
  config = {
    MEME_Backend: "https://localhost:3000/",
    WS_BASE_URL: "http://localhost:5100/api",
    DOMAIN_NAME: "https://localhost:5001",
    DEMO: false,
    // recaptcha: recaptchaToken,
  };
} else if (status === "TEST") {
  config = {
    MEME_Backend: process.env.REACT_APP_MEMEBACKEND, // Chat Server
    WS_BASE_URL: process.env.REACT_APP_ADMINBACKENDSERVER, // Admin Authentication server
    DOMAIN_NAME: process.env.REACT_APP_DOMAIN,
    DEMO: false,
    // recaptcha: recaptchaToken,
  };
} else if (status === "PROD") {
  config = {
    MEME_Backend: "https://apis.arishti.in/", // Chat Server
    WS_BASE_URL: "https://memeadmin.arishti.in/api/api/", // Admin Authentication server
    DOMAIN_NAME: "https://memeadmin.arishti.in/",
    DEMO: false,
    // recaptcha: recaptchaToken,
  };
} else if (status === "CUSTOM") {
  config = {
    MEME_Backend: process.env.REACT_APP_MEMEBACKEND, // Chat Server
    WS_BASE_URL: process.env.REACT_APP_ADMINBACKENDSERVER, // Admin Authentication server
    DOMAIN_NAME: process.env.REACT_APP_DOMAIN,
    DEMO: false,
    // recaptcha: recaptchaToken,
  };
} else {
  console.log(" ---- THE SYSTEM IS NOT CONNECTED TO ENV FILE ----");
}
export default config;
