/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import {
  deleteActiveSession,
  getUserInfo,
  inactiveSessionLogs,
  logout,
} from "../../network/ApiAxios";
import { TailSpin } from "react-loader-spinner";
import customToast from "views/examples/ToastComponent";
import { AUTH_ROUTE_PATHS } from "routes-constants";
import { ADMIN_ROUTE_PATHS } from "routes-constants";

const AdminNavbar = (props) => {
  let username = JSON.parse(localStorage.getItem("user"))?.name;
  let orgToken = JSON.parse(localStorage.getItem("user"))?.orgToken;
  let pic = JSON.parse(localStorage.getItem("user"))?.profilePicture;
  const orgName = JSON.parse(localStorage.getItem("keys"))?.org_name;
  const loginTime = JSON.parse(localStorage.getItem("loginTime"));
  let id = JSON.parse(localStorage.getItem("user"))?._id;
  const isSuperAdmin = JSON.parse(localStorage.getItem("user"))?.isSuperAdmin;
  const [ip, setIp] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const dispatch = useDispatch();
  const [decodedImage, setDecodedImage] = useState("");

  useEffect(() => {
    if (username == null) {
      localStorage.clear();
      props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`,);
    }
    let user = localStorage.getItem("user");
    let token = localStorage.getItem("token");
    if (!user || !token) {
      // const response = await logovalueokenvalue		// const { value } = response;
      dispatch({ type: "LOGOUT" });
      localStorage.clear();
      props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`,);
    }
  }, [decodedImage, username]);

  useEffect(() => {
    try {
      const f = async () => {
        const response = await fetch("https://ipapi.co/json/");
        if (!response) window.location.reload();
        const data = await response.json();
        // console.log("data", data);
        setIp(data?.ip);
        setCity(data?.city);
        setCountry(data?.country_name);
      };
      f();
    } catch (error) {
      window.location.reload();
    }
  }, []);

  const logOut = async () => {
    await inactiveSessionLogs(
      id,
      loginTime,
      ip,
      window.navigator.userAgent,
      username,
      pic,
      orgToken,
      city,
      country
    );
    await deleteActiveSession(id);
    const token = localStorage.getItem("token");
    // console.log(res);
    if (token) {
      const response = await logout(token);
      const { data } = response;
      dispatch({ type: "LOGOUT" });
      localStorage.clear();
      props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`,);
    }
  };

  useEffect(() => {
    getImage();
  }, [decodedImage]);
  const getImage = async () => {
    console.log("getuser info called navbar");
    const { data } = await getUserInfo(
      JSON.parse(localStorage.getItem("user"))?.email
    );
    try {
      if (username) {
        try {
          const value = data?.userinfo[0].profilePicture;
          if (!value || value === null || value === undefined) {
            setDecodedImage("https://www.arishti.in/images/group-image2.webp");
          } else {
            setDecodedImage(value);
          }
        } catch (error) {
          setDecodedImage("https://www.arishti.in/images/group-image2.webp");
        }
      } else {
        props.history.push(AUTH_ROUTE_PATHS.AUTH_LAYOUT + AUTH_ROUTE_PATHS.LOGIN);
      }
    } catch (error) {
      console.log(error, "adminNavbar error");
      if (
        error.response &&
        (error.response.status === 429 || error.response.status === 403)
      ) {
        customToast(
          "",
          true,
          "",
          "Too many requests",
          `${error.response.message}`,
          "Reload the website",
          ""
        );
      }
    }
  };
  // getImage();
  // console.log(decodedImage, "decodedImage");

  // console.log(props, "sa");

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            className="h5 mb-0 d-none d-lg-inline-block pt-3 ml--4"
            to="/"
            style={{
              textDecoration: "none",
              color: `${props.location.pathname === `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`
                ? "#585858"
                : "#ffffff"
                }`,
            }}
          >
            {props.brandText + " | " + (orgName || "")}
          </Link>

          <Nav
            className="align-items-center d-none d-md-flex justify-content-end mt-lg--5  mr--4"
            navbar
          >
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center ">
                  <Media className="d-none d-lg-block ">
                    <span
                      className="mb-0 text-sm font-weight-bold"
                      style={{
                        color: `${props.location.pathname === `${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`
                          ? "#3107cb"
                          : "#ffffff"
                          }`,
                      }}
                    >
                      {username}
                    </span>
                    <br />
                    <span
                      className="mb-0 text-sm "
                      style={{ color: "#d3d3d3" }}
                    >
                      {isSuperAdmin === true ? "Super Admin" : "Admin"}
                    </span>
                  </Media>
                  <span className="avatar avatar-sm rounded-circle ml-2">
                    <img
                      alt="..."
                      src={
                        decodedImage
                          ? decodedImage
                          : "https://www.arishti.in/images/group-image2.webp"
                      }
                      style={{
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      }}
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem to={`${ADMIN_ROUTE_PATHS.ADMIN_TEST_LAYOUT}${ADMIN_ROUTE_PATHS.EDIT_PROFILE}`} tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={logOut}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
