import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ADMIN_ROUTE_PATHS } from 'routes-constants';

const AuthRoutes = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        !(localStorage.getItem("token") && localStorage.getItem("user"))
            ? <Component {...props} />
            : <Redirect to={ADMIN_ROUTE_PATHS.ADMIN_LAYOUT + ADMIN_ROUTE_PATHS.INDEX} />
    )} />
);

export default AuthRoutes;
