import React, { useState } from "react";

// reactstrap components
import { Button, FormGroup, Form } from "reactstrap";
import {
  login,
  sendOtp,
  verifiedOtp,
  verifyAndLogin,
  validateLogin,
} from "../../network/ApiAxios";
import Toast from "react-bootstrap/Toast";
import config from "../../config";

import logo from "../../assets/Message Me - Logo on Dark.png";
import banner from "../../assets/Sign In Illustration.svg";
import banner1 from "../../assets/Sign In Illustration – 2.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import memeLogo from "../../assets/img/icons/common/memeLogo.png";

import "../../assets/css/bootstrap.css";
import "../../assets/css/login.css";
import "../../assets/css/twoFa.css";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import registrationSvg from "../../assets/registration.svg";
import Countdown, { zeroPad } from "react-countdown";
import OTPInput from "react-otp-input";
import customToast from "./ToastComponent";
import { AUTH_ROUTE_PATHS } from "routes-constants";

const TwoFactorAuth = (props) => {
  const [emailOtp, setEmailOtp] = useState("");
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [disableResend, setDisableResend] = useState(true);
  const [timer, setTimer] = useState(Date.now());
  const { userId } = useSelector((state) => state.setUserIdReducer);

  // console.log(props, "props");
  const verifyOtp1 = async () => {
    try {
      // console.log(props.location.customNameData);
      const { data } = await verifyAndLogin(
        props.location.customNameData,
        emailOtp,
        props.location.email,
        props.location.password
      );
      console.log(data, "data 1111");
      // const response = await verifiedOtp(
      //   props.location.customNameData,
      //   emailOtp
      // );
      // const { data } = response;
      // console.log(data);
      // if (data.success === false) {
      //   setError(data.message);
      //   setToastMessage("OTP Expired");
      // }
      // console.log(data, "data otp");
      if (data.success == true) {
        const data1 = await validateLogin(data.userId, data.TwoFAToken);
        console.log(data1, "data 1 validated login");
        if (data1.data.success === true) {
          setToastMessage("Login Successful");
          localStorage.setItem("token", data1.data.token);
          localStorage.setItem("user", JSON.stringify(data1.data.user));
          localStorage.setItem("twoFa", JSON.stringify(data1.data.user.twoFA));
          localStorage.setItem(
            "loginTime",
            JSON.stringify(data1.data.loginTime)
          );
          localStorage.setItem("ip", JSON.stringify(data1.data.ip));
          localStorage.setItem(
            "expiryDate",
            JSON.stringify(data1.data.expiryDate)
          );
          if (data1.data.showPopup) {
            localStorage.setItem(
              "showPopup",
              JSON.stringify(data1.data.showPopup)
            );
          }
          // console.log(data);
          props.history.push("/");
        } else {
          customToast("", true, "", "Something went wrong!", ``, "", "");
        }
      } else if (data.tempBan === true) {
        customToast(
          "",
          true,
          "",
          "Enter correct OTP",
          `${data.msg}`,
          "Try Again",
          ""
        );
      } else if (data.otpExpired === true) {
        customToast(
          "",
          true,
          "",
          "OTP Expired",
          `${data.message}`,
          "Try Again",
          ""
        );
      } else if (data.wrongAttempts === true) {
        customToast(
          "",
          true,
          "",
          "Wrong attempts exceeded",
          `${data.msg}`,
          "Try Again",
          ""
        );
      } else if (data.invalidCreds === true) {
        customToast(
          "",
          true,
          "",
          "Enter correct credentials",
          `${data.msg}`,
          "Try Again with correct credentials",
          ""
        );
      } else if (data.invalidOtp === true) {
        customToast(
          "",
          true,
          "",
          "Invalid OTP",
          `${data.message}`,
          "Enter correct otp from email",
          ""
        );
      }
      //   const { data } = await login(
      //     props.location.email,
      //     props.location.password
      //   );
      //   setError("");
      // } else {
      //   customToast(
      //     "",
      //     true,
      //     "",
      //     "Enter correct OTP",
      //     "Entered OTP is wrong or it has been expired",
      //     "Try Again",
      //     ""
      //   );
      //   setError(data.errors);
      // }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        customToast(
          "",
          true,
          "",
          "Too many wrong attempts",
          "Number of attempts exceeded",
          "Try Again after 10 minutes",
          ""
        );
      }
    }
  };

  useEffect(() => {
    if (!userId) props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`);
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    verifyOtp1();
  };

  useEffect(() => {
    if (disableResend === true) {
      setTimeout(() => {
        setDisableResend(false);
      }, 5 * 60 * 1000);
    }
  }, [disableResend]);

  useEffect(() => {
    setTimer(Date.now() + 5 * 60 * 1000);
  }, []);

  const callAgain = async () => {
    setTimeout(() => {
      setDisableResend(false);
    }, 5 * 60 * 1000);

    setTimer(Date.now() + 5 * 60 * 1000);

    if (!userId) {
      // props.history.push(`/auth/newAdmin/${userId}`);
      customToast(
        "",
        true,
        "",
        "Page Refreshed",
        "Please go back and again login",
        "Try Again",
        ""
      );
      props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`);
    } else {
      const { data } = await sendOtp(userId);
      if (data.success === true) {
        // console.log(data);
        setDisableResend(true);
        customToast(
          true,
          "",
          "",
          "OTP sent",
          "Kindly check your registered mail address and enter the OTP",
          "Check Email",
          ""
        );
      } else {
        setError(data.errors);
        customToast(
          "",
          true,
          "",
          "Enter correct OTP",
          "Entered OTP is wrong or it has been expired",
          "Try Again",
          ""
        );
        setEmailOtp("");
      }
    }
  };

  const handleChangeOtp = (emailOtp) => {
    const numericOtp = emailOtp.replace(/\D/g, "");
    setEmailOtp(numericOtp);
  };

  const renderer = ({ minutes, seconds }) => {
    // Render a countdown
    // console.log("hii");
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section className="">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col col-xl-9 mid">
              <div
                // className="card"
                style={{
                  borderRadius: "8px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  border: "1px solid rgba(0,0,0,0.125)",
                }}
              >
                <div className="">
                  {/* <div className="col-md-6 d-none d-md-block resetImage overflow-hidden"></div> */}
                  <div className="d-flex align-items-center justify-content-around">
                    <div>
                      <img
                        src={registrationSvg}
                        style={{
                          width: "100%",
                          objectFit: "cover",
                        }}
                        className="d-flex align-items-center justify-content-center pt-4 "
                      />
                    </div>

                    <div
                      className=" d-flex align-items-center"
                      style={{ width: "100%" }}
                    >
                      <div className=" text-black p-4 p-md-2">
                        <img
                          src={memeLogo}
                          alt="logo"
                          style={{
                            top: "7px",
                            position: "absolute",
                            right: "0px",
                            paddingRight: "15px",
                          }}
                        />
                        <h3 className="h2heading text-center mt-md--2">
                          Two Factor Authentication Verification
                        </h3>
                        <p className="text-center">
                          Enter the verification code we sent to
                        </p>
                        <p className="text-center font-bold">
                          {props.location.email}
                        </p>
                        <br></br>

                        <div className="d-flex justify-content-center w-100 align-items-center bg-grey flex-col">
                          <p className="d-flex align-items-start  w-100 pl-2 pb-2">
                            Type your 6 digit security code
                          </p>
                          <OTPInput
                            value={emailOtp}
                            onChange={handleChangeOtp}
                            numInputs={6}
                            renderSeparator={<span></span>}
                            inputStyle="inputStyle"
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                        {disableResend === true ? (
                          <div className="d-flex align-items-center mb-2 pt-2">
                            <p className="mr-2 pl-2 ">Time Remaining: </p>{" "}
                            <Countdown
                              date={timer}
                              renderer={renderer}
                              zeroPadTime={2}
                              autoStart={true}
                              key={timer}
                            />
                          </div>
                        ) : (
                          <div className="d-flex align-items-center mb-2 pt-2">
                            <p className="mr-2 pl-2">Time Remaining: </p>{" "}
                            <Countdown
                              date={timer}
                              renderer={renderer}
                              zeroPadTime={2}
                              autoStart={true}
                              key={timer}
                            />
                          </div>
                        )}

                        <div className="d-flex align-items-center justify-content-between pt-2 ml-2">
                          <a
                            href={
                              config.DOMAIN_NAME +
                              AUTH_ROUTE_PATHS.AUTH_LAYOUT +
                              AUTH_ROUTE_PATHS.CONFIRM_EMAIL +
                              props.location.customNameData
                            }
                          >
                            <Button
                              // color="primary"
                              type="submit"
                              className="signIn"
                              onClick={(e) => handleClick(e)}
                              style={{
                                boxShadow: "none",
                                borderColor: "rgba(217, 217, 217, 0.48)",
                                width: "22rem",
                                cursor:
                                  !emailOtp || timer === 1698497713679
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              disabled={!emailOtp || timer === 1698497713679}
                            >
                              Verify OTP
                            </Button>{" "}
                          </a>
                          <Button
                            color="primary"
                            className={`${disableResend === true
                              ? "resend ml-2 cursor-not-allowed"
                              : "resend ml-2"
                              }`}
                            style={{
                              boxShadow: "none",
                              marginRight: "5px",
                              background: "rgba(217, 217, 217, 0.48)",
                              borderColor: "rgba(217, 217, 217, 0.48)",
                              color: "#6A6969",
                              width: "12rem",
                            }}
                            onClick={callAgain}
                            disabled={disableResend}
                          >
                            Resend OTP
                          </Button>
                        </div>
                      </div>
                    </div>

                    <br></br>
                    {/* <Form
                      role="form"
                      onSubmit={(values) => {
                        handleClick(values);
                      }}
                    >
                      <FormGroup className="form-group p-sm-1">
                        <div className="floating-label">
                          <input
                            id="emailOtp"
                            className="form-control"
                            type="number"
                            name="emailOtp"
                            onChange={(e) => setEmailOtp(e.target.value)}
                            value={emailOtp}
                            autoComplete="email"
                            required
                          />
                          <label htmlFor="first">Email OTP</label>
                        </div>
                      </FormGroup>
                      <a
                        href={
                          config.DOMAIN_NAME +
                          //"/auth/confirm-email/" +
                          props.location.customNameData
                        }
                        style={{ textDecoration: "none" }}
                      >
                        {error && (
                            <p
                              style={{
                                color: "red",
                                paddingBottom: "5px",
                                textUnderlineOffset: "none",
                              }}
                            >
                              <strong>{error}</strong>
                            </p>
                          ) &&
                          func()}
                        <Button
                          color="primary"
                          type="submit"
                          className="signIn"
                        >
                          Verify
                        </Button>
                      </a>
                    </Form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="p-3 my-2 rounded"
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "fixed",
          minHeight: "100px",
          width: "15%",
          right: 10,
          top: 50,
          zIndex: 50,
        }}
      >
        <Toast
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: 20,
            backgroundColor: "white",
          }}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header
            style={{
              color: "#3107CB",
              padding: 5,
              icon: "danger",
            }}
          >
            <img
              style={{ height: "30px", width: "100px" }}
              src={require("assets/img/brand/message-me.png").default}
              alt="meme"
            />
          </Toast.Header>
          <Toast.Body
            style={{
              color: "red",
              padding: 5,
            }}
          >
            {toastMessage}
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
};

export default TwoFactorAuth;
