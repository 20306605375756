import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AUTH_ROUTE_PATHS } from 'routes-constants';
const loginPath = `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`;
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        localStorage.getItem("token") && localStorage.getItem("user")
            ? <Component {...props} />
            : <Redirect to={loginPath} />
    )} />
);

export default PrivateRoute;
