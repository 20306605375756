import React, { useState } from "react";
import { Link, Redirect, useParams, useLocation } from "react-router-dom";

import logo from "../../assets/Message Me - Logo on Dark.png";
import banner from "../../assets/Sign In Illustration.svg";
import banner1 from "../../assets/Sign In Illustration – 2.svg";

import "../../assets/css/bootstrap.css";
import "../../assets/css/login.css";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import Toast from "react-bootstrap/Toast";
import {
  newAdmin,
  register,
  sendOtp,
  updateRegisterModel,
} from "../../network/ApiAxios";
import config from "../../config";
import memeLogo from "../../assets/img/icons/common/memeLogo.png";
import { sha256 } from "js-sha256";
import { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Carousel } from "react-responsive-carousel";
import { toast, ToastContainer, Zoom } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUserId } from "components/redux/adminActions";
import { setCurrentDate } from "components/redux/adminActions";
import customToast from "./ToastComponent";
import ValidatedInput from "functions/ValidatedInput";
import { AUTH_ROUTE_PATHS } from "routes-constants";
import { ADMIN_ROUTE_PATHS } from "routes-constants";

const NewAdmin = (props) => {
  // const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const token = searchParams.get("token");
  const [valid, setValid] = useState(false);
  console.log(id, "id");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [orgToken, setOrgToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [userID, setUserID] = useState(null);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
    useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) {
      setValid(false);
    } else {
      const asyncFunction = async () => {
        var { data } = await newAdmin(id);
        console.log(data);
        if (data.message === null) {
          customToast(
            "",
            true,
            "",
            `Error`,
            "Some error occurred, kindly ask super admin to resend email.",
            "Go to Subscription",
            `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.SUBSCRIPTION}`
          );
        } else {
          // setName(data.message.name);
          setEmail(data.message.email);
          setOrgToken(data.message.orgToken);
          setNumber(data.message.orgName);
        }
      };
      asyncFunction();
    }
  }, []);

  const updateNewUser = async () => {
    if (!(name && email && number && orgToken && password && confirmPassword)) {
      setError("Make sure to fill all the inputs");
      setToastMessage("Make sure to fill all the inputs");
      setShowToast(true);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setToastMessage("Passwords do not match");
      setShowToast(true);
      return;
    }
    var hash = sha256(password);
    console.log(hash, "hash");
    const { message, userID, ac, expired, passwordExpired } =
      await updateRegisterModel(name, email, orgToken, hash, token, id);
    if (expired === true) {
      customToast(
        "",
        true,
        "",
        `${passwordExpired}`,
        "Contact Super Admin to reinvite you",
        "Inform Super Admin",
        `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`
      );
    } else if (ac === true) {
      setError("This admin is already  verified.");
    } else {
      console.log(message, userID);
      dispatch(setUserId(message._id));
      dispatch(setCurrentDate(Date.now()));
      // const { data } = response;
      // console.log(data.userID);
      const { data } = await sendOtp(message._id);
      console.log(data, "resp");
      if (data.success === false) {
        setToastMessage(data.message);
        setError(data.message);
        setToastMessage(data.message);
        setShowToast(true);
        return;
      }
      if (data.success === true) {
        customToast(
          true,
          "",
          "",
          "OTP sent",
          "Kindly check your registered mail address and enter the OTP",
          "Check Email",
          ""
        );
      }
      if (config.DEMO) {
        setToastMessage(
          "This is a demo, so we will not send you an email. Instead, click on this link to verify your account:"
        );
        setUserID(id);
      }
      setError("");
      setName("");
      setEmail("");
      setNumber("");
      setOrgToken("");
      setPassword("");
      setShowToast(true);
      console.log(data.message._id, "userid");
      props.history.push({
        pathname: `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.OTP}`,
        customNameData: userID,
      });
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    // if (!number.match("[0-9]{10}")) {
    //   alert("Please put 10 digit mobile number");
    //   return;
    // }
    updateNewUser();
  };

  const [isNameValid, setIsNameValid] = useState(true);
  const handleNameBlur = () => {
    setIsNameValid(
      name.length > 0 && !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]+/.test(name)
    );
    if (isNameValid) {
      setNameErrorMessage("");
    } else {
      setNameErrorMessage("Name should not contain special symbols.");
    }
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    const containsSpecialChars =
      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]+/.test(newName);

    // Check if the name length is less than 2 characters
    setIsNameValid(newName.length > 0 && !containsSpecialChars);
    if (isNameValid) {
      setNameErrorMessage("");
    } else {
      setNameErrorMessage("Name should not contain special symbols.");
    }
  };

  const clearError = () => {
    setIsNameValid(true);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Password validation rules
    const hasValidLength = newPassword.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const hasSpecialChar = /[!@#%^&*()_+{}\[\]:;<>,.?~\-\/\\]/.test(
      newPassword
    );
    const hasNoDollar = !/\$/.test(newPassword); // Excluding $
    setIsPasswordValid(
      hasValidLength &&
      hasAlphabet &&
      hasNumber &&
      hasSpecialChar &&
      hasNoDollar
    );
    if (isPasswordValid) {
      setPasswordErrorMessage("");
    } else {
      setPasswordErrorMessage(
        "Password must be 8+ chars with num & special symbol except $."
      );
    }
  };

  const handlePasswordBlur = () => {
    // Password validation rules
    const hasValidLength = password.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#%^&*()_+{}\[\]:;<>,.?~\-\/\\]/.test(password);
    const hasNoDollar = !/\$/.test(password); // Excluding $
    setIsPasswordValid(
      hasValidLength &&
      hasAlphabet &&
      hasNumber &&
      hasSpecialChar &&
      hasNoDollar
    );
    if (isPasswordValid) {
      setPasswordErrorMessage("");
    } else {
      setPasswordErrorMessage(
        "Password must be 8+ chars with num & special symbol except $."
      );
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    // Compare with the original password
    setIsPasswordMatch(newConfirmPassword === password);
    if (isPasswordMatch) {
      setConfirmPasswordErrorMessage("");
    } else {
      setConfirmPasswordErrorMessage("Passwords do not match");
    }
  };

  const handleConfirmPasswordBlur = () => {
    setIsPasswordMatch(confirmPassword === password);
    if (isPasswordMatch) {
      setConfirmPasswordErrorMessage("");
    } else {
      setConfirmPasswordErrorMessage("Passwords do not match");
    }
  };
  const clearPasswordErrors = () => {
    setIsPasswordValid(true);
  };

  const clearPasswordMatchError = () => {
    setIsPasswordMatch(true);
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <section className="">
        <div className="fixed blueSmallCircle d-none d-lg-block"></div>
        <div className="fixed blueSolidCircle d-none d-lg-block"></div>
        <div className="fixed blueHollowCircle d-none d-lg-block"></div>
        <div className="container mt-10 mb-md-5 py-md-0 mt-md--4">
          <div className="row d-flex justify-content-center align-items-center mx-auto">
            <div className="col col-xl-10">
              <div
                className="card shadow-xl shadow-intensity-xl shadow shadow-offset-down-lg shadow-offset-left-lg cardShadow"
                style={{
                  borderTopLeftRadius: "0.7em",
                  borderTopRightRadius: "0.7em",
                  borderBottomRightRadius: "0.7em",
                }}
              >
                <div className="row g-0">
                  <div className="col-md-6 d-none d-md-block newAdminImage overflow-hidden"></div>
                  <div className="col-md-6 d-flex align-items-center">
                    <div className="card-body text-black">
                      <img
                        src={memeLogo}
                        alt="logo"
                        style={{
                          top: "7px",
                          position: "absolute",
                          right: "0px",
                          paddingRight: "25px",
                          height: "40.45px",
                          width: "166.6px",
                        }}
                      />
                      <h3 className="h2heading text-center mt-4">
                        New Admin Registration
                      </h3>
                      <br></br>
                      <Form
                        role="form"
                        onSubmit={(values) => {
                          handleClick(values);
                        }}
                      >
                        <ValidatedInput
                          label="Full Name"
                          onChange={handleNameChange}
                          onBlur={handleNameBlur}
                          value={name}
                          isValid={isNameValid}
                          onFocus={clearError}
                          placeholder="Full name"
                          errorMessage={nameErrorMessage}
                        />
                        <FormGroup className=" form-group">
                          <div className="floating-label">
                            <input
                              id="email"
                              className="form-control"
                              type="email"
                              style={{
                                backgroundColor: "#EDF2F7",
                                border: "none",
                              }}
                              name="email"
                              value={email}
                              required
                            />
                            <label
                              for="first"
                              style={{
                                color: "#455768",
                                backgroundColor: "#EDF2F7",
                              }}
                            >
                              Email
                            </label>
                          </div>
                        </FormGroup>
                        {/* THIS IS ORG NAME NOW */}
                        <FormGroup className=" form-group">
                          <div className="floating-label">
                            <input
                              id="orgName"
                              className="form-control"
                              type="text"
                              name="orgName"
                              style={{
                                backgroundColor: "#EDF2F7",
                                border: "none",
                              }}
                              value={number}
                              // pattern="[0-9]{10}"
                              // minLength="9"
                              // maxLength="10"
                              required
                            />
                            <label
                              for="orgName"
                              style={{
                                color: "#455768",
                                backgroundColor: "#EDF2F7",
                              }}
                            >
                              Organization Name
                            </label>
                          </div>
                        </FormGroup>
                        <FormGroup className=" form-group">
                          <div className="floating-label">
                            <input
                              id="orgtoken"
                              className="form-control"
                              type="text"
                              style={{
                                backgroundColor: "#EDF2F7",
                                border: "none",
                              }}
                              name="orgtoken"
                              value={orgToken}
                              required
                            />
                            <label
                              for="first"
                              style={{
                                color: "#455768",
                                backgroundColor: "#EDF2F7",
                              }}
                            >
                              Organization Token
                            </label>
                          </div>
                        </FormGroup>
                        <ValidatedInput
                          label="Password"
                          onChange={handlePasswordChange}
                          onBlur={handlePasswordBlur}
                          onFocus={clearPasswordErrors}
                          value={password}
                          isValid={isPasswordValid}
                          placeholder="Enter your password"
                          errorMessage={passwordErrorMessage}
                          type="password"
                        />

                        <ValidatedInput
                          label="Confirm Password"
                          onChange={handleConfirmPasswordChange}
                          onBlur={handleConfirmPasswordBlur}
                          onFocus={clearPasswordMatchError}
                          value={confirmPassword}
                          isValid={isPasswordMatch}
                          placeholder="Confirm your password"
                          errorMessage={confirmPasswordErrorMessage}
                          type="password"
                        />

                        {error ? (
                          <div className="text-muted">
                            <small>
                              {" "}
                              <span className="text-red font-weight-700">
                                {error}
                              </span>
                            </small>
                          </div>
                        ) : null}
                        <div className="rowkey">
                          <Button
                            className="inline-block-child signIn"
                            color="primary"
                            type="submit"
                          >
                            Sign Up
                          </Button>
                          <Link
                            to="/signIn"
                            className="inline-block-child forgettext text-center"
                          >
                            Already have an Account?
                          </Link>
                        </div>
                        <div className="text-center"></div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="p-3 my-2 rounded"
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "fixed",
          minHeight: "100px",
          width: "15%",
          right: 10,
          top: 50,
          zIndex: 50,
        }}
      >
        <Toast
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: 20,
            backgroundColor: "white",
          }}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header
            style={{
              color: "#3107CB",
              padding: 5,
              icon: "danger",
            }}
          >
            <img
              style={{ height: "30px", width: "100px" }}
              src={require("assets/img/brand/message-me.png").default}
            />
          </Toast.Header>
          <Toast.Body
            style={{
              color: "red",
              padding: 5,
            }}
          >
            {toastMessage}
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
};

export default NewAdmin;
