/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import IdleTimer from "react-idle-timer";
import {
  deleteActiveSession,
  inactiveSessionLogs,
  logout,
} from "../network/ApiAxios";
import { AUTH_ROUTE_PATHS } from "routes-constants";
import { ADMIN_ROUTE_PATHS } from "routes-constants";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: 1000 * 5 * 20,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      ip: "",
      city: "",
      country: "",
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }
  _onAction(e) {
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    this.setState({ isTimedOut: false });
  }

  async _onIdle(e) {
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      const token = localStorage.getItem("token");
      const id = JSON.parse(localStorage.getItem("user"))?._id;
      const pic = JSON.parse(localStorage.getItem("user"))?.profilePicture;
      const username = JSON.parse(localStorage.getItem("user"))?.name;
      const loginTime = JSON.parse(localStorage.getItem("loginTime"));

      const resp = await deleteActiveSession(id);
      const res = await inactiveSessionLogs(
        id,
        loginTime,
        this.state.ip,
        window.navigator.userAgent,
        username,
        pic,
        token,
        this.state.city,
        this.state.country
      );
      const response = await logout(token);
      const { data } = response;
      localStorage.clear();
      this.props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`);
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }
  componentDidUpdate(e) {
    //document.documentElement.scrollTop = 0;
    //document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  componentDidMount() {
    try {
      const f = async () => {
        const response = await fetch("https://ipapi.co/json/");
        if (!response) window.location.reload();
        const data = await response.json();
        // console.log("data", data);
        this.setState({ ip: data?.ip });
        this.setState({ city: data?.city });
        this.setState({ country: data?.country_name });
      };
      f();
    } catch (error) {
      window.location.reload();
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === ADMIN_ROUTE_PATHS.ADMIN_LAYOUT) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  render() {
    return (
      <>
        <IdleTimer
          ref={(ref) => (this.idleTimer = ref)}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`,
            imgSrc: require("assets/img/brand/message-me.png").default,
            imgAlt: "...",
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to={`${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`} />
          </Switch>
          <Container>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;
