import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
// import memeLogo from "../../../assets/img/icons/common/memeLogo.png";
import memeLogo from "../../../assets/img/icons/common/MeMeOg.png";
import {
  Button,
  //Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import ValidatedInput from "functions/ValidatedInput";
import {
  getTwoFa,
  login,
  loginCheck,
  sendOtpFor2FA,
  verifyRecaptcha,
  validateLogin,
  sendOtp,
  getCaptcha,
} from "../../../network/ApiAxios";
import { RotatingLines } from "react-loader-spinner";
import { sha256 } from "js-sha256";
import { Link, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserId } from "components/redux/adminActions";
import customToast from "views/examples/ToastComponent";
// import ReCAPTCHA from "react-google-recaptcha";
import "../../../assets/css/login.css";
import { AUTH_ROUTE_PATHS } from "routes-constants";

const LoginForm = ({ props }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [activeChild, setActiveChild] = useState(0);
  const [password, setPassword] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [captchaUrl, setCaptchaUrl] = useState("");
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [captcha, setCaptcha] = useState("");
  const [captchaErrorMessage, setCaptchaErrorMessage] = useState("");
  const [isCaptchaValid, setIsCaptchaValid] = useState(true);
  // const [showRecaptcha, setShowRecaptcha] = useState(false);
  // const [recaptchaChecked, setRecaptchaChecked] = useState(false);

  const items = useMemo(() => [1, 2, 3, 4], []);

  const changeChild = useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        // If supposed previous child is < 0 set it to last child
        setActiveChild((a) => (a - 1 < 0 ? items.length - 1 : a - 1));
      } else if (e.key === "ArrowRight") {
        // If supposed next child is > length -1 set it to first child
        setActiveChild((a) => (a + 1 > items.length - 1 ? 0 : a + 1));
      }
    },
    [items]
  );

  useEffect(() => {
    document.addEventListener("keydown", changeChild);

    return function cleanup() {
      document.removeEventListener("keydown", changeChild);
    };
  });
  useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      const response = await getCaptcha(Date.now());
      setCaptchaUrl(response.data.captcha); // Assuming the URL is in `response.data.url`
      console.log("Response is ", response.data);
    } catch (err) {
      console.error("Error fetching captcha:", err);
    }
  };

  const refreshCaptcha = async () => {
    // Append a timestamp to bypass caching
    const temp = await getCaptcha(Date.now());
    setCaptchaUrl(temp.data.captcha);
  };

  // const handleRecaptchaChange = async (value) => {
  //   // console.log(value, "recaptcha");
  //   if (value) {
  //     const response = await verifyRecaptcha(value); // Send verification only when checkbox is checked
  //     console.log(response, "verifyRecap");
  //     if (response.data.success === true) {
  //       setLoggedIn(false);
  //       setShowRecaptcha(false);
  //       setRecaptchaChecked(true);
  //     }
  //   }
  // };

  const handleClick = async (event) => {
    try {
      setLoggedIn(true);
      event.preventDefault();
      var hash = sha256(password);
      const response = await loginCheck(email, captcha, hash);
      console.log(response, "handleClick");
      // if (response.data.showRecaptcha === true) {
      // setShowRecaptcha(true);
      // return;
      // }
      if (response.data.accountConfirmation === false) {
        const { data } = await sendOtp(response.data.id);
        dispatch(setUserId(response.data.id));
        if (data.success === true) {
          // console.log(data);
          customToast(
            true,
            "",
            "",
            "OTP sent",
            "Kindly check your registered mail address and enter the OTP",
            "Check Email",
            ""
          );
        }

        props.history.push({
          pathname: `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.OTP}`,
          customNameData: response.data.id,
          email: email,
          accountConfirmation: false,
        });
      }
      if (response.data.success === false) {
        setError(response.data.msg);
        setLoggedIn(false);
        setTimeout(() => {
          setError("");
        }, 3500);
      } else {
        const { next, userId, token } = response.data;
        if (next === true) {
          var hash1 = sha256(password);
          dispatch(setUserId(userId));
          const resp = await sendOtpFor2FA(userId);
          props.history.push({
            pathname: `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.TWO_FA_OTP}`,
            customNameData: userId,
            password: hash1,
            email: email,
          });
        } else {
          const data1 = await validateLogin(userId, token);
          console.log(data1, "data 1 validated login");
          if (data1.data.success === true) {
            setToastMessage("Login Successful");
            localStorage.setItem("token", data1.data.token);
            localStorage.setItem("user", JSON.stringify(data1.data.user));
            localStorage.setItem(
              "twoFa",
              JSON.stringify(data1.data.user.twoFA)
            );
            localStorage.setItem(
              "loginTime",
              JSON.stringify(data1.data.loginTime)
            );
            localStorage.setItem(
              "expiryDate",
              JSON.stringify(data1.data.expiryDate)
            );
            localStorage.setItem("ip", JSON.stringify(data1.data.ip));
            if (data1.data.showPopup) {
              localStorage.setItem(
                "showPopup",
                JSON.stringify(data1.data.showPopup)
              );
            }
            // console.log(data);
            props.history.push("/");
          } else {
            setError("Something went wrong");
            setTimeout(() => {
              setError("");
            }, 3500);
            setLoggedIn(false);
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle the rate limit exceeded error
        setError("Too many wrong attempts, try again later after 10 minutes");
        setTimeout(() => {
          setError("");
        }, 3500);
        setLoggedIn(false);
      } else {
        // Handle other errors
        console.log("error ", error);
        setError(error ? error : "An error occurred.");
        setTimeout(() => {
          setError("");
        }, 3500);
        setLoggedIn(false);
      }
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Email validation rules
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailPattern.test(newEmail);
    setIsEmailValid(isValid);
    setEmailErrorMessage(isValid ? "" : "Invalid email format.");
  };

  const handleEmailBlur = () => {
    // Email validation rules
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsEmailValid(emailPattern.test(email));
    if (isEmailValid) {
      setEmailErrorMessage("");
    } else {
      setEmailErrorMessage("Invalid email format.");
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    // setIsPasswordTouched(true);
    // Password validation rules
    const hasValidLength = newPassword.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const isValid = hasValidLength && hasAlphabet && hasNumber;
    setIsPasswordValid(isValid);
    setPasswordErrorMessage(
      isValid
        ? ""
        : newPassword.length === 0
          ? ""
          : "Password must be 8+ chars with num & special symbol."
    );
  };

  const handlePasswordBlur = () => {
    // Password validation rules
    const hasValidLength = password.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    setIsPasswordValid(hasValidLength && hasAlphabet && hasNumber);
    setPasswordErrorMessage(
      isPasswordValid
        ? ""
        : password.length === 0
          ? ""
          : "Password must be 8+ chars with num & special symbol."
    );
  };
  console.log(props, "props");
  return (
    <>
      <div className="fixed smallNewGreenCircleBottom d-none d-lg-block"></div>
      <div
        className="d-flex align-items-center pt-4"
      // style={{ backgroundColor: "#edf0f5" }}
      >
        <div className="card-body text-black">
          {/* <div className="fixed loginGreenCircleHollow d-none d-lg-block "></div> */}
          <div className="d-flex align-items-center justify-content-center">
            <img
              src={memeLogo}
              alt="logo"
              style={{
                width: "200px",
                height: "54px",
              }}
            />
          </div>
          <h3 className="h3heading text-center mt-5">Sign in to Admin Panel</h3>
          <br></br>
          <Form
            role="form"
            onSubmit={(values) => {
              handleClick(values);
            }}
            className="px-4"
          >
            <label style={{ color: "#585858", display: "flex" }}>
              <strong>E-mail</strong>
            </label>
            <ValidatedInput
              label="Email"
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              onFocus={() => setIsEmailValid(true)}
              value={email}
              isValid={isEmailValid}
              placeholder="Enter your email"
              errorMessage={emailErrorMessage}
              type="email"
            />
            <label style={{ color: "#585858", display: "flex" }}>
              <strong>Password</strong>
            </label>
            <ValidatedInput
              label="Password"
              onChange={handlePasswordChange}
              onBlur={handlePasswordBlur}
              onFocus={() => setIsPasswordValid(true)}
              value={password}
              isValid={isPasswordValid}
              placeholder="Enter your password"
              errorMessage={passwordErrorMessage}
              type="password"
            // ref={passwordInputRef?.current}
            />
            <div style={{ margin: "1rem 0" }}>
              {/* <label style={{ color: "#585858", display: "flex" }}>
                <strong>Captcha</strong>
              </label> */}
              <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                <img
                  src={captchaUrl}
                  // alt={captchaUrl}
                  style={{ border: "1px solid #ddd", borderRadius: "4px" }}
                />
                <ValidatedInput
                  label="Captcha"
                  onChange={(e) => setCaptcha(e.target.value)}
                  value={captcha}
                  isValid={isCaptchaValid}
                  placeholder="Enter captcha"
                  errorMessage={captchaErrorMessage}
                  type="text"
                />
              </div>
            </div>
            {error ? (
              <div className="text-muted">
                <small>
                  {" "}
                  <span className="text-red font-weight-900">{error}</span>
                </small>
              </div>
            ) : null}
            <div className="d-md-flex justify-content-between ">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label
                  className="form-check-label"
                  htmlFor="flexCheckChecked"
                  style={{ color: "#585858" }}
                >
                  Remember Me
                </label>
              </div>
              <Link
                to={`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.RESET_PASSWORD}`}
                className="inline-block-child forgettext"
              >
                Forgot Password?
              </Link>
            </div>
            {/* {showRecaptcha && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                onChange={handleRecaptchaChange}
              />
            )} */}
            <div className="rowkey">
              <Button
                className={`${emailErrorMessage || passwordErrorMessage || loggedIn
                  ? "inline-block-child signIn cursor-not-allowed"
                  : "inline-block-child signIn"
                  }`}
                color="primary"
                type="submit"
                disabled={
                  emailErrorMessage ||
                  passwordErrorMessage ||
                  loggedIn ||
                  !isEmailValid ||
                  !isPasswordValid
                }
              >
                {loggedIn === false ? (
                  "Log In"
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="26"
                      height="26"
                      visible={true}
                    />
                  </div>
                )}
              </Button>
            </div>
          </Form>
          <div className="d-flex align-items-center justify-content-center">
            <hr className="hr1" />
          </div>
          <br />
          <div className="rowkey">
            <a
              className="inline-block-child needaccounttext text-center "
              onClick={() => props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.REGISTER}`)}
            >
              Need an Account?
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
