import React from "react";
import { Fade, Slide } from "react-slideshow-image";
import loginPg from "../../../assets/login.svg";
import loginPg1 from "../../../assets/login1.svg";
import loginPg2 from "../../../assets/login2.svg";
import BackedBy from "../../../assets/img/Auth/BackedBy.png";
import "../../../assets/css/login.css";
import "react-slideshow-image/dist/styles.css";
import MainCarousel from "./Carousel";

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  height: "300px",
  width: "550px",
  borderRadius: "20px",
};
const slideImages = [
  {
    url: "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    caption: "Remote Wipe",
    description: "Lorem ipsum",
  },
  {
    url: "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
    caption: "Delete User",
    description: "Lorem ipsum",
  },
  {
    url: "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    caption: "File Type Limiter",
    description: "Lorem ipsum",
  },
];

const Slideshow = () => {
  return (
    <>
      <div
        className="text-white pt-4 slideShowCenter"
        style={{ backgroundColor: "#59B498", height: "100vh" }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <h1
            className="font-bold pt-2 py-4"
            style={{
              color: "#fff",
              fontSize: "150%",
            }}
          >
            Why Choose Us?
          </h1>
        </div>
        {/* SLIDESHOW IMAGE */}
        {/* <div className="slide-container">
          <Fade
            arrows={false}
            autoplay={true}
            pauseOnHover={true}
            canSwipe={true}
            duration={2000}
          >
            {slideImages.map((slideImage, index) => (
              <React.Fragment>
                <div className="d-flex flex-col align-items-center justify-content-center">
                  <p className="pb-4 font-bold">Data Traceability</p>
                  <p
                    className="pb-4 d-flex align-items-center justify-content-center text-center"
                    style={{ width: "50%" }}
                  >
                    MessageMe enables the enterprise to trace and track their
                    data being shared within employees
                  </p>
                </div>
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div
                    style={{
                      ...divStyle,
                      backgroundImage: `url(${slideImage.url})`,
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      border: "5px white",
                    }}
                    className="shadow"
                  ></div>
                </div>
                {/* <div className="d-flex align-items-center justify-content-center py-4 flex-col">
                <p className="text-white font-bold">{slideImage.caption}</p>
                <p className="text-white">{slideImage.description}</p>
              </div> */}
        {/* </React.Fragment>
            ))}
          </Fade>
        </div> */}

        <MainCarousel />

        {/* BACKED BY */}
        {/* <div className="d-flex align-items-center justify-content-center flex-col pt-8">
          <h1 className="font-bold pb-4">Backed By</h1>
          <img
            src={BackedBy}
            style={{ width: "220px", height: "50px" }}
            className="pb-2"
          />
        </div> */}
        <div className="d-flex align-items-center justify-content-center flex-col pt-8" style={{ marginTop: "30px" }}>
          <span className="font-bold pb-4"><i>Making your enterprise ready for critical communications</i></span>
          {/* <img
            src={BackedBy}
            style={{ width: "220px", height: "50px" }}
            className="pb-2"
          /> */}
        </div>
      </div >
    </>
  );
};

export default Slideshow;
