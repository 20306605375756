/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import fslImageUI from "../../assets/img/illustrations/fslImage.png";
import kmsImage from "../../assets/img/illustrations/kmsImage.png";
// reactstrap components
import {
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Modal,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import Card from "react-bootstrap/Card";

// core components
import UserHeader from "components/Headers/UserHeader.js";
import EditHeader from "../../components/Headers/EditHeader";
import fslImage from "../../assets/img/appsettings/fsl.png";
import {
  deleteKMSPairs,
  edit,
  getFileSize,
  getUser,
  getUserInfo,
  saveAsymDataToDB,
  saveAsymServerPKDataToDB,
  saveDataToDB,
  saveFileLimit,
  fileSharingRestriction,
  enabledWebAppFromAdmin,
  disabledWebAppFromAdmin,
} from "../../network/ApiAxios";
import Header from "../../components/Headers/Header";
import Form from "react-bootstrap/Form";

import Sidebar from "components/Sidebar/Sidebar";
import Button from "react-bootstrap/Button";

import Cards from "@material-tailwind/react/Card";
import Image from "react-bootstrap/Image";
import "../../assets/css/new.css";
import Paragraph from "@material-tailwind/react/Paragraph";
import H6 from "@material-tailwind/react/Heading6";

import { memeUsers, remoteWipe } from "../../network/ApiAxios";
import { Slide, ToastContainer, Zoom, toast } from "react-toastify";
import ButtonComp from "./Button";
// import * as AWS from "@aws-sdk/client-kms";
import FileTypeLimiterSVG from "../../assets/img/PrivacyPolicy/Privacy.svg";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  makeStyles,
} from "@material-ui/core";
import { setFileSize } from "components/redux/adminActions";
import { useDispatch, useSelector } from "react-redux";
import Utf8ArrayToStr from "functions/unittostr";
import { AiFillFile } from "react-icons/ai";
import { BiWorld } from "react-icons/bi";
import customToast from "./ToastComponent";
import { IoCheckmarkCircle } from "react-icons/io5";
import { istConversion } from "functions/timeConversion";
import { TailSpin, ThreeCircles } from "react-loader-spinner";
import FileTypeCheckbox from "views/ReusableComponents/FileTypeCheckBox";
import Heading6 from "@material-tailwind/react/Heading6";
import { Heading1 } from "@material-tailwind/react";
// import Utf8ArrayToStr from "../../functions/unittostr";
import dashboardImage from "../../assets/carousel/new_admin_dashboard.jpeg";

const Integrations = (props) => {
  let orgToken = JSON.parse(localStorage.getItem("user"))?.orgToken;
  const userId = JSON.parse(localStorage.getItem("user"))?._id;
  const isSuperAdmin = JSON.parse(localStorage.getItem("user"))?.isSuperAdmin;
  let AdminEmail = JSON.parse(localStorage.getItem("user"))?.email;
  const permission = JSON.parse(localStorage.getItem("user"))?.adminPermissions;
  const orgDetails = JSON.parse(localStorage.getItem("keys"));
  let defaultFileSize = JSON.parse(localStorage.getItem("fsl"));
  const [modal, setModal] = useState(false);
  const [disableKMSModal, setDisableKMSModal] = useState(false);
  const [isFileSizeProcessing, setFileProcessing] = useState(false);
  const [isFileTypeProcessing, setFileTypeProcessing] = useState(false);

  const [fileTypeModal, setFileTypeModal] = useState(false);
  const [fsl, setFsl] = useState("");
  const [symId, setSymId] = useState("");
  const [asymId, setAsymId] = useState("");
  const [spk, setSpk] = useState("");
  const [doReRender, setDoReRender] = useState(0);
  const [disable, setDisable] = useState(false);
  const [users, setUsers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fileSharingCustomButtons, setFileSharingCustomButtons] = useState([]);
  const [selectValue, setSelectValue] = useState(defaultFileSize);
  const [loaderForFileType, setLoaderForFileType] = useState(false);
  const [fileType, setFileType] = useState({
    png: false,
    jpg: false,
    pdf: false,
    mp4: false,
    heic: false,
    wav: false,
    checkFileType: false,
  });
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  // console.log(orgDetails.fileSharingRestriction, "sdsd");
  const toggle = () => {
    setModal(!modal);
    setDisable(false);
  };
  const disableKMSToggle = () => {
    setDisableKMSModal(!disableKMSModal);
    setDisable(false);
  };
  const toggleFileType = () => {
    setFileTypeModal(!fileTypeModal);
    setFileType({
      png: orgDetails.fileSharingRestriction.PNG === true ? true : false,
      jpg: orgDetails.fileSharingRestriction.JPG === true ? true : false,
      pdf: orgDetails.fileSharingRestriction.PDF === true ? true : false,
      mp4: orgDetails.fileSharingRestriction.MP4 === true ? true : false,
      heic: orgDetails.fileSharingRestriction.HEIC === true ? true : false,
      wav: orgDetails.fileSharingRestriction.WAV === true ? true : false,
    });
  };
  const [height, setHeight] = useState(0);
  const [heightFsl, setHeightFsl] = useState(0);
  const ref = useRef(null);
  const fslRef = useRef(null);

  useEffect(() => {
    console.log("Rerendered");
  }, [doReRender]);

  useEffect(() => {
    console.log(
      "I will run whenever symmetric and asymmetric key id will change."
    );
    updateUser();
  }, [fsl]);

  // useEffect(() => {
  //   if (ref.current.clientHeight || fslRef.current.clientHeight) {
  //     setTimeout(() => {
  //       setHeight(ref.current?.clientHeight);
  //       setHeightFsl(fslRef.current?.clientHeight);
  //     }, 1000);
  //   }

  //   // if (ref.current.clientHeight || fslRef.current.clientHeight) {
  //   //   setTimeout(() => {
  //   //   }, 1000);
  //   // }
  // }, [window.innerWidth, height, heightFsl]);

  const handleClick = async (e) => {
    setDisable(true);

    // e.currentTarget.disabled = true;
    toggle();
  };
  const handleClickDisableKMS = async (e) => {
    alert("hello")
    setDisable(true);
    try {

      const response = await disabledWebAppFromAdmin();
      // console.log("response ", response, response?.success, response?.data)
      if (response.data?.success === true) {
        console.log("Web app successfully disabled. Proceeding...");
        const fileSharing = localStorage.getItem("keys");
        const parsedObject = JSON.parse(fileSharing);
        parsedObject.is_webapp_enabled = false;
        const modifiedNdStringifiedForStorage = JSON.stringify(parsedObject);
        localStorage.setItem("keys", modifiedNdStringifiedForStorage);

        customToast(
          true,
          "",
          "",
          "Web application disabled",
          "Users can now login on web app by scanning qr code from application",
          "",
          "",
          ""
        );
      } else {
        customToast(
          "",
          true,
          "",
          "Something went wrong",
          "",
          "",
          "",
          ""
        );
      }

      disableKMSToggle();
    } catch (e) {
      customToast(
        "",
        true,
        "",
        "Something went wrong",
        "",
        "",
        "",
        ""
      );
      disableKMSToggle();
    }

  };

  const [fslLoading, setFslLoading] = useState(false);
  const updateUser = async () => {
    setFslLoading(true);
    const newUser = await getFileSize(orgToken);
    setFslLoading(false);
    if (newUser.data.success === true) {
      localStorage.setItem("keys", JSON.stringify(newUser.data.organization));
      setSymId(newUser.data.organization.ORG_KEY_ID_SYM);
      setAsymId(newUser.data.organization.ORG_KEY_ID_ASYM);
      setSpk(newUser.data.organization.Server_Public_Key);
      setFsl(newUser.data.fsl);
      setSelectValue(newUser.data.fsl);
    }
  };

  const enableWebApp = async () => {
    try {

      const response = await enabledWebAppFromAdmin();
      // console.log("response ", response, response?.success, response?.data)
      if (response.data?.success === true) {
        const fileSharing = localStorage.getItem("keys");
        const parsedObject = JSON.parse(fileSharing);
        parsedObject.is_webapp_enabled = true;
        const modifiedNdStringifiedForStorage = JSON.stringify(parsedObject);
        localStorage.setItem("keys", modifiedNdStringifiedForStorage);

        console.log("Web app successfully enabled. Proceeding...");
        customToast(
          true,
          "",
          "",
          "Web application enabled",
          "Users can now login on web app by scanning qr code from application",
          "",
          "",
          ""
        );
      } else {
        customToast(
          "",
          true,
          "",
          "Something went wrong",
          "",
          "",
          "",
          ""
        );
      }

      toggle();
    } catch (e) {
      customToast(
        "",
        true,
        "",
        "Something went wrong",
        "",
        "",
        "",
        ""
      );
      toggle();

    }
  };

  const handleFileChange = async (fileSize) => {
    setFileProcessing(true);
    const res = await saveFileLimit(fileSize, orgToken, userId);
    // dispatch(setFileSize(fileSize, AdminEmail, orgToken));
    // console.log(res, "fileChange");
    // const oldFsl = JSON.parse(localStorage.getItem("user"))?.fileSizeLimit;
    // if (oldFsl) {
    // console.log(fsl, "fsl log");
    if (res.data.success === true) {
      localStorage.setItem("fsl", fileSize);
      const newUser = await getUserInfo(AdminEmail);
      if (newUser.data.success === true) {
        localStorage.setItem("user", JSON.stringify(newUser.data.userinfo[0]));
      }
      updateUser();

      customToast(
        true,
        "",
        "",
        "File Size Limiter Updated",
        `File size limit for the employees has been set to ${selectValue} MB`,
        "",
        "",
        ""
      );

      // window.location.reload();
    } else {
      customToast(
        "",
        true,
        "",
        "Error Occurred",
        `${res.data.message}`,
        "",
        "",
        ""
      );
    }
    setFileProcessing(false);

  };

  const handleChangeFileType = (event) => {
    const { name, checked } = event.target;
    setFileType((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const updateFileSharingRestrictions = async () => {
    // png, pdf, jpg, mp4, heic, wav;
    try {
      setFileTypeProcessing(true);
      setLoaderForFileType(true);
      const { data } = await fileSharingRestriction(
        fileType.png,
        fileType.pdf,
        fileType.jpg,
        fileType.mp4,
        fileType.heic,
        fileType.wav,
        orgToken,
        userId
      );
      console.log(data, "update file sharing");
      if (data.success === true) {
        setLoaderForFileType(false);
        customToast(
          true,
          "",
          "",
          "File Sharing Restrictions Updated",
          `${data.msg}`,
          "New File Sharing Restrictions Applied",
          "",
          ""
        );
        const fileSharingRestriction = {
          PNG: fileType.png,
          PDF: fileType.pdf,
          JPG: fileType.jpg,
          MP4: fileType.mp4,
          HEIC: fileType.heic,
          WAV: fileType.wav,
        };
        const fileSharing = localStorage.getItem("keys");
        const parsedObject = JSON.parse(fileSharing);
        parsedObject.fileSharingRestriction = fileSharingRestriction;
        parsedObject.lastDeleteOrgKeys = new Date();
        const modifiedNdStringifiedForStorage = JSON.stringify(parsedObject);
        localStorage.setItem("keys", modifiedNdStringifiedForStorage);

        toggleFileType();
      } else {
        setLoaderForFileType(false);
        customToast(
          "",
          true,
          "",
          "Error Occurred while updating restrictions",
          `${data.msg}`,
          "Try Again Later",
          "",
          ""
        );
      }
    } catch (error) {
      setFileTypeProcessing(false);

      console.log(error.response.data, "err");
      if (error.response.data.recaptchaToken === "false") {
        setLoaderForFileType(false);
        customToast(
          "",
          true,
          "",
          "Error Occurred while updating restrictions",
          `${error.response.data.message}`,
          "Reload the page or try login logout once.",
          "",
          ""
        );
      }
    }
    setFileTypeProcessing(false);
  };

  const disableKMSkeys = async () => {
    disableKMSToggle();
    setDisable(true);
    try {

      const response = await disabledWebAppFromAdmin();
      // console.log("response ", response, response?.success, response?.data)
      if (response.data?.success === true) {
        console.log("Web app successfully disabled. Proceeding...");
        const fileSharing = localStorage.getItem("keys");
        const parsedObject = JSON.parse(fileSharing);
        parsedObject.is_webapp_enabled = false;
        parsedObject.lastDeleteOrgKeys = new Date();
        const modifiedNdStringifiedForStorage = JSON.stringify(parsedObject);
        localStorage.setItem("keys", modifiedNdStringifiedForStorage);

        customToast(
          true,
          "",
          "",
          "Web application disabled",
          "Users can now login on web app by scanning qr code from application",
          "",
          "",
          ""
        );
      } else {
        customToast(
          "",
          true,
          "",
          "Something went wrong",
          "",
          "",
          "",
          ""
        );
      }

      disableKMSToggle();
    } catch (e) {
      customToast(
        "",
        true,
        "",
        "Something went wrong",
        "",
        "",
        "",
        ""
      );
      disableKMSToggle();
    }
  };

  // const useStyles = makeStyles((theme) => ({
  //   formControl: {
  //     width: "100px",
  //     height: "35px", // Adjust the height as needed
  //     "& .MuiFilledInput-root": {
  //       padding: "10px", // Adjust the padding as needed
  //       height: "35px", // Adjust the height as needed
  //       borderRadius: "10px",
  //     },
  //     "& .MuiFilledInput-root:focus": {
  //       borderRadius: "10px", // Ensure borderRadius is set for focus state
  //       padding: "10px", // Adjust the padding as needed
  //       height: "35px",
  //     },
  //     "& .Mui-focused": {
  //       backgroundColor: "transparent", // Remove background color on focus
  //     },
  //   },
  // }));
  const useStyles = makeStyles((theme) => ({
    formControl: {
      width: "100px",
      height: "40px",
      "& .MuiFilledInput-root": {
        borderRadius: "10px",
        padding: "5px",
        height: "100%",
        // display: "flex",
        // justifyContent: "center",
        // // alignItems: "center",
        // justifyContent: "center",
      },
      "& .Mui-focused .MuiFilledInput-root .MuiSelect-select .MuiSelect-selectMenu":
      {
        borderRadius: "10px",
        // padding: "5px",
        // height: "100%",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
      },
      "& .Mui-focused .MuiSelect-select:focus": {
        backgroundColor: "transparent",
        // padding: "5px",
        // height: "100%",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
      },
      "& .makeStyles-formControl-42 .Mui-focused .MuiSelect-select:focus": {
        padding: "0px",
      },
    },
  }));
  const classes = useStyles();

  // FILE SHARING BTN RENDERING UI LOGIC

  const fixedFileSharingCustomButtons = Object.entries(
    orgDetails?.fileSharingRestriction
  )
    .filter(([key, value]) => value)
    .map(([key]) => (
      <Button
        key={key}
        size="sm"
        style={{
          backgroundColor: "#d3d3d3",
          borderColor: "#d3d3d3",
          outline: "none !important",
          outlineOffset: "none !important",
          boxShadow: "none",
          width: "50px",
          borderRadius: "12px",
          color: "black",
          fontSize: "10px",
          marginRight: "5px",
        }}
        className="d-flex align-items-center justify-content-center font-bold"
      >
        {key.toUpperCase()}
      </Button>
    ));

  // RENDERING FIXED BTN OUTSIDE CONTAINER
  const fixedRenderCustomButtons =
    fixedFileSharingCustomButtons.length > 0 ? (
      Object.entries(orgDetails?.fileSharingRestriction)
        .filter(([key, value]) => value)
        .map(([key]) => (
          <Button
            key={key}
            size="sm"
            style={{
              backgroundColor: "#d3d3d3",
              borderColor: "#d3d3d3",
              outline: "none !important",
              outlineOffset: "none !important",
              boxShadow: "none",
              width: "50px",
              borderRadius: "12px",
              color: "black",
              fontSize: "10px",
              marginRight: "5px",
            }}
            className="d-flex align-items-center justify-content-center font-bold"
          >
            {key.toUpperCase()}
          </Button>
        ))
    ) : (
      <Heading1 style={{ fontSize: "12px", color: "red" }}
        className="font-bold">
        Selcte atleast one file type
      </Heading1>
    );

  useEffect(() => {
    const updatedButtons = Object.entries(orgDetails?.fileSharingRestriction)
      .filter(([key, value]) => fileType[key.toLowerCase()])
      .map(([key]) => ({
        key,
        label: key.toUpperCase(),
      }));

    setFileSharingCustomButtons(updatedButtons);
  }, [fileType]);

  // FILE SHARING CUSTOM BUTTON STATE
  const renderCustomButtons =
    fileSharingCustomButtons.length === 0 ? (
      <Heading1 style={{ fontSize: "12px", color: "red" }}
        className="font-bold">
        Selcte atleast one file type
      </Heading1>
    ) : (
      fileSharingCustomButtons.map(({ key, label }) => (
        <Button
          key={key}
          size="sm"
          style={{
            backgroundColor: "#d3d3d3",
            borderColor: "#d3d3d3",
            outline: "none !important",
            outlineOffset: "none !important",
            boxShadow: "none",
            width: "50px",
            borderRadius: "12px",
            color: "black",
            fontSize: "10px",
            marginRight: "5px",
          }}
          className="d-flex align-items-center justify-content-center font-bold"
        >
          {label}
        </Button>
      ))
    );

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Header />

      {/*  MODAL FOR ENABLING WEB APP */}
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="md"
        style={{
          height: "200px",
        }}
      >
        <Cards
          style={{
            height: "150px",
          }}
        >
          <Image
            style={{
              marginTop: "5px",
              width: "75%",
              height: "20% !important",
            }}
            className="d-flex align-items-center justify-content-center mx-auto"
            src={dashboardImage}
            alt="Card Image"
          />

          {/* <CardBody> */}
          <H6 color="gray">MessageMe™ Web-App</H6>
          <p className="text-gray">Dear Admin, </p>
          <Paragraph>
            Prepare to activate the web-app utility for your enterprise and note
            the following pointers and dependent modules affecting your
            infrastructure:
          </Paragraph>
          {/* </CardBody> */}

          <div
            style={{
              marginLeft: "10px",
              top: "-20px",
            }}
          >
            <div className="d-flex align-items-center mb-2">
              <IoCheckmarkCircle size={19} color="#47be9b" className="mr-1" />{" "}
              <p
                style={{
                  marginLeft: "5px",
                  color: "black",
                  lineHeight: "22px",
                  fontSize: "14px",
                }}
              >
                AWS KMS ensures end-to-end encryption in the web app
              </p>
            </div>
            <div className="d-flex align-items-center mb-2">
              <IoCheckmarkCircle size={19} color="#47be9b" className="mr-1" />{" "}
              <p
                style={{
                  marginLeft: "5px",
                  color: "black",
                  lineHeight: "22px",
                  fontSize: "14px",
                }}
              >
                The web app is accessible via QR code scanning.
              </p>
            </div>

            <div className="d-flex align-items-center mb-2">
              <IoCheckmarkCircle size={19} color="#47be9b" className="mr-1" />{" "}
              <p
                style={{
                  marginLeft: "5px",
                  color: "black",
                  lineHeight: "22px",
                  fontSize: "14px",
                }}
              >
                Use MessageMe app to scan QR code and start using the web app.
              </p>
            </div>

            <div className="d-flex align-items-center mb-2">
              <IoCheckmarkCircle size={21} color="#47be9b" className="mr-1" />{" "}
              <p
                style={{
                  marginLeft: "5px",
                  color: "black",
                  lineHeight: "22px",
                  fontSize: "14px",
                }}
              >
                This enterprise web app restricts certain features for data loss
                prevention.
              </p>
            </div>
          </div>
          <div
            style={{
              marginLeft: "10px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={disable}
                  onChange={({ target: { checked } }) => {
                    setDisable(checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                  color="primary"
                />
              }
              label="Accept Terms and Conditions"
              style={{
                color: "#141414",
                fontWeight: "300",
                // paddingRight: "4rem",
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginLeft: "20px",
              paddingBottom: "10px",
            }}
            className="btns"
          >
            <Button
              disabled={!disable}
              onClick={enableWebApp}
              variant="primary"
              style={{ borderRadius: "50px", width: "100%" }}
            >
              Yes
            </Button>
            <Button
              variant="light"
              style={{ borderRadius: "50px", width: "100%" }}
              onClick={() => {
                toggle();
                setDisable(false);
              }}
            >
              No
            </Button>
            {/* text={"Yes"} toggle={enableWebApp} /> */}
            {/* <ButtonComp text={"No"} toggle={toggle} /> */}
          </div>
        </Cards>
      </Modal>

      {/* MODAL FOR DISABLING WEB APP */}
      <Modal
        isOpen={disableKMSModal}
        toggle={disableKMSToggle}
        centered
        size="md"
        style={{
          height: "200px",
        }}
      >
        <Cards
          style={{
            height: "150px",
          }}
        >
          <Image
            style={{
              marginTop: "5px",
              width: "75%",
              height: "20% !important",
            }}
            className="d-flex align-items-center justify-content-center mx-auto"
            src={
              dashboardImage
            }
            alt="Card Image"
          />

          {/* <CardBody> */}
          <H6 color="gray">MessageMe™ Web-App</H6>
          <p className="text-gray">Dear Admin, </p>
          <Paragraph>
            Prepare to deactivate the web-app utility for your enterprise and
            note the following pointers and dependent modules affecting your
            infrastructure:
          </Paragraph>
          {/* </CardBody> */}

          <div
            style={{
              marginLeft: "10px",
              top: "-20px",
            }}
          >
            <div className="d-flex align-items-center mb-2">
              <IoCheckmarkCircle size={19} color="#47be9b" className="mr-1" />{" "}
              <p
                style={{
                  marginLeft: "5px",
                  color: "black",
                  lineHeight: "22px",
                  fontSize: "14px",
                }}
              >
                AWS KMS ensures end-to-end encryption in the web app
              </p>
            </div>
            <div className="d-flex align-items-center mb-2">
              <IoCheckmarkCircle size={19} color="#47be9b" className="mr-1" />{" "}
              <p
                style={{
                  marginLeft: "5px",
                  color: "black",
                  lineHeight: "22px",
                  fontSize: "14px",
                }}
              >
                The web app will not be accessible via QR code scanning.
              </p>
            </div>

            <div className="d-flex align-items-center mb-2">
              <IoCheckmarkCircle size={19} color="#47be9b" className="mr-1" />{" "}
              <p
                style={{
                  marginLeft: "5px",
                  color: "black",
                  lineHeight: "22px",
                  fontSize: "14px",
                }}
              >
                Chat and other various functionalities of Web App won't work
                now.
              </p>
            </div>

            <div className="d-flex align-items-center mb-2">
              <IoCheckmarkCircle size={21} color="#47be9b" className="mr-1" />{" "}
              <p
                style={{
                  marginLeft: "5px",
                  color: "black",
                  lineHeight: "22px",
                  fontSize: "14px",
                }}
              >
                This enterprise web app restricts certain features for data loss
                prevention.
              </p>
            </div>
          </div>
          <div
            style={{
              marginLeft: "10px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={disable}
                  onChange={({ target: { checked } }) => {
                    setDisable(checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                  color="primary"
                />
              }
              label="Accept Terms and Conditions"
              style={{
                color: "#141414",
                fontWeight: "300",
                // paddingRight: "4rem",
              }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginLeft: "20px",
              paddingBottom: "10px",
            }}
            className="btns"
          >
            <Button
              disabled={!disable}
              onClick={disableKMSkeys}
              variant="primary"
              style={{ borderRadius: "50px", width: "100%" }}
            >
              Yes
            </Button>
            <Button
              variant="light"
              style={{ borderRadius: "50px", width: "100%" }}
              onClick={() => {
                disableKMSToggle();
                setDisable(false);
              }}
            >
              No
            </Button>
          </div>
        </Cards>
      </Modal>

      {/* MODAL FOR FILE TYPE LIMITER */}
      <Modal
        isOpen={fileTypeModal}
        toggle={toggleFileType}
        centered
        size="md"
        style={{
          height: "200px",
        }}
      >
        <Cards
          style={{
            height: "150px",
          }}
        >
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h1 className="text-black font-bold" style={{ fontSize: "20px" }}>
              Update File Type
            </h1>
            <Image src={FileTypeLimiterSVG} />
          </div>

          <div
            className="d-flex align-items-center text-black justify-content-between"
            style={{ width: "75%" }}
          >
            <div className="d-flex align-items-center">
              <FileTypeCheckbox
                color="#d3d3d3"
                id="react"
                name="png"
                value="png"
                label="PNG"
                checked={fileType.png}
                onChange={handleChangeFileType}
              />
            </div>
            <div className="d-flex align-items-center ">
              <FileTypeCheckbox
                color="#d3d3d3"
                id="react"
                name="jpg"
                value="jpg"
                label="JPG/JPEG"
                checked={fileType.jpg}
                onChange={handleChangeFileType}
              />
            </div>
          </div>
          <div
            className="d-flex align-items-center text-black justify-content-between"
            style={{ width: "66%" }}
          >
            <div className="d-flex align-items-center">
              <FileTypeCheckbox
                color="#d3d3d3"
                id="react"
                name="wav"
                value="wav"
                label="WAV"
                checked={fileType.wav}
                onChange={handleChangeFileType}
              />
            </div>
            <div className="d-flex align-items-center ">
              <FileTypeCheckbox
                color="#d3d3d3"
                id="react"
                name="pdf"
                value="pdf"
                label="PDF"
                checked={fileType.pdf}
                onChange={handleChangeFileType}
              />
            </div>
          </div>
          <div
            className="d-flex align-items-center text-black justify-content-between mb-2"
            style={{ width: "91%" }}
          >
            <div className="d-flex align-items-center">
              <FileTypeCheckbox
                color="#d3d3d3"
                id="react"
                name="mp4"
                value="mp4"
                label="MP4/AVI"
                checked={fileType.mp4}
                onChange={handleChangeFileType}
              />
            </div>
            <div className="d-flex align-items-center ">
              <FileTypeCheckbox
                color="#d3d3d3"
                id="react"
                name="heic"
                value="heic"
                label="HEIC (iPhone Images)"
                checked={fileType.heic}
                onChange={handleChangeFileType}
              />
            </div>
          </div>

          <div
            style={{ backgroundColor: "#EAEAEA", height: "45px" }}
            className="p-2 mb-2 d-flex "
          >
            {renderCustomButtons}
          </div>

          <div className="d-flex">
            <Checkbox
              color="primary"
              id="react"
              name="checkFileType"
              label="React"
              value={"checkFileType"}
              checked={fileType.checkFileType}
              onChange={handleChangeFileType}
            />
            <p
              style={{ fontSize: "12px", color: "#3109cb" }}
              className="font-bold"
            >
              Selecting above file types will completely disable the other files
              to be shared on the platform.
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <Button
              size="sm"
              style={{
                backgroundColor: "#3109cb",
                borderColor: "#3109cb",
                outline: "none !important",
                outlineOffset: "none !important",
                boxShadow: "none",
                width: "85px",
                borderRadius: "10px",
                color: "white",
              }}
              className="d-flex align-items-center justify-content-center font-bold"
              disabled={fileSharingCustomButtons.length === 0 || isFileTypeProcessing ? true : !fileType.checkFileType || loaderForFileType}
              onClick={updateFileSharingRestrictions}
            >
              {isFileTypeProcessing ? 'Processing' : "Update"}
            </Button>
          </div>
        </Cards>
      </Modal>

      {/* Page content */}
      <div className="p-4">
        <h6 style={{ color: "#000000" }}>
          <b>Application Settings</b>{" "}
        </h6>
        <p style={{ color: "#74798C", marginBottom: "12px" }}>
          You can activate various capabilities for application through this
          page
        </p>

        <div className="d-flex align-items-lg-center flex-column flex-lg-row pt-2 flex-wrap">
          {/* FIRST CARD */}
          <div
            style={{
              borderRadius: "36px",
              backgroundColor: "#fff",
              boxShadow: "10px 10px 10px 1px rgba(0, 0, 0, 0.08)",
            }}
            className="text-black mr-lg-4 mb-sm-4 mb-lg-4"
          >
            <div className="d-flex align-items-center justify-content-between">
              <img
                src={fslImageUI}
                alt="fslUI"
                width={200}
                style={{ height: "120px" }}
              />
              <h1
                className="pr-2"
                style={{
                  fontWeight: "400",
                  color: "#585858",
                  fontSize: "21px",
                }}
              >
                File Size <br /> Limiter
              </h1>
            </div>
            <div className="d-flex align-items-center pl-4 pt-4 pb-2">
              <div
                style={{
                  backgroundColor: "#47BE9B",
                  width: "60px",
                  height: "54px",
                  borderRadius: "10px",
                }}
                className="d-flex align-items-center justify-content-center"
              >
                {fslLoading === true ? (
                  <TailSpin
                    height="20"
                    width="20"
                    color="#fff"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{ fontWeight: 400 }}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  <h6 className="align-items-center d-flex text-white absolute">
                    <span>
                      <b>{fsl}</b>
                    </span>
                    mb
                  </h6>
                )}
              </div>

              <div className="pl-4">
                <h6 style={{ fontSize: "15px" }}>Current Limit Activity:</h6>
                {orgDetails?.lastUpdateFileSizeName ? (
                  <p style={{ fontSize: "13px" }} className="pr-4">
                    File size was set by{" "}
                    <b>{orgDetails?.lastUpdateFileSizeName}</b> on <br />
                    {istConversion(orgDetails?.lastUpdateFileSizeLimit)}
                  </p>
                ) : (
                  <p style={{ fontSize: "13px" }} className="pr-2">
                    By default the File Size Limit is set to 2mb <br /> by the
                    system
                  </p>
                )}
              </div>
            </div>
            <div
              // className="d-flex align-items-center pb-2"
              className={`${permission.fsl === false && isSuperAdmin === false
                ? "d-flex align-items-center pb-4 mt-2"
                : "d-flex align-items-center pb-2"
                }`}
            >
              <div className="d-flex flex-col pl-4 mr-8">
                <p style={{ fontSize: "14px" }}>Update Limit</p>
                <div className="">
                  {isSuperAdmin === true || permission.fsl === true ? (
                    <FormControl
                      variant="filled"
                      size="small"
                      // style={{
                      //   borderRadius: "20px",
                      //   width: "100px",
                      // }}
                      className={classes.formControl}
                    >
                      <Select
                        value={selectValue}
                        disableUnderline
                        style={{ paddingBottom: "12px" }}
                        displayEmpty
                        onChange={(e) => setSelectValue(e.target.value)}
                      >
                        <MenuItem value={2}>2MB</MenuItem>
                        <MenuItem value={10}>10MB</MenuItem>
                        <MenuItem value={50}>50MB</MenuItem>
                        <MenuItem value={200}>200MB</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <p
                      className=" font-weight-bold d-flex align-items-center mb-0"
                      style={{ color: "gray" }}
                    >
                      {fsl} MB
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-4 pr-4">
                <Button
                  type="button"
                  style={{
                    color: "#fff",
                    backgroundColor: "#47be9b",
                    width: "85px",
                    borderColor: "#47be9b",
                    outline: "none !important",
                    outlineOffset: "none !important",
                    boxShadow: "none",
                    borderRadius: "10px",
                  }}
                  size="sm"
                  disabled={
                    isFileSizeProcessing ? true : isSuperAdmin === false ? permission.fsl === false : ""
                  }
                  onClick={() => handleFileChange(parseInt(selectValue))}
                >
                  {isFileSizeProcessing ? "Processing" : "Apply"}
                </Button>
              </div>
            </div>
          </div>

          {/* SECOND CARD */}
          <div
            style={{
              borderRadius: "36px",
              backgroundColor: "#fff",
              boxShadow: "10px 10px 10px 1px rgba(0, 0, 0, 0.08)",
            }}
            className="text-black mr-lg-4 mb-sm-4 mb-lg-4"
          >
            <div className="d-flex align-items-center justify-content-between">
              <img
                src={kmsImage}
                alt="kmsImage"
                width={200}
                style={{ height: "120px" }}
              />
              <h1
                className="pl-6 pr-2"
                style={{
                  fontWeight: "400",
                  color: "#585858",
                  fontSize: "21px",
                }}
              >
                File Type <br /> Limiter
              </h1>
            </div>

            <div className="d-flex align-items-center pb-2 pl-4 pt-4">
              <IoCheckmarkCircle size={20} color="#47be9b" className="mr-1" />{" "}
              {isSuperAdmin === true ||
                permission.fileSharingPrevention === true ? (
                <p style={{ fontSize: "13px" }}>
                  You can restrict the data sharing to specific <br /> file
                  types as below:
                </p>
              ) : permission.fileSharingPrevention === false ? (
                <p style={{ fontSize: "13px" }}>
                  You don't have the permission to
                  <br /> use this functionality.
                </p>
              ) : (
                <p style={{ fontSize: "13px" }}>
                  Only Super Admins are allowed to
                  <br /> use this functionality.
                </p>
              )}
            </div>

            <div className="d-flex pl-4 pb-2 flex-column">
              <p className="font-bold text-black" style={{ fontSize: "13px" }}>
                Current Allowed File Types:{" "}
              </p>

              <div className="d-flex align-items-center">
                {fixedRenderCustomButtons}
              </div>
            </div>

            <div className="d-flex align-items-center pb-2 justify-content-end pr-4">
              <Button
                // color="danger"
                size="sm"
                style={{
                  marginLeft: "14px",
                  backgroundColor: "#47be9b",
                  borderColor: "#47be9b",
                  outline: "none !important",
                  outlineOffset: "none !important",
                  boxShadow: "none",
                  borderRadius: "10px",
                  width: "85px",
                }}
                onClick={toggleFileType}
                disabled={
                  isSuperAdmin === false
                    ? permission.fileSharingPrevention === false
                    : ""
                }
              >
                Update
              </Button>
            </div>
          </div>

          {/* THIRD CARD */}
          <div
            style={{
              borderRadius: "36px",
              backgroundColor: "#fff",
              boxShadow: "10px 10px 10px 1px rgba(0, 0, 0, 0.08)",
            }}
            className="text-black mb-lg-4"
          >
            <div className="d-flex align-items-center justify-content-between">
              <img
                src={kmsImage}
                alt="kmsImage"
                width={200}
                style={{ height: "120px" }}
              />
              <h1
                className="pl-4 pr-2"
                style={{
                  fontWeight: "400",
                  color: "#585858",
                  fontSize: "21px",
                }}
              >
                MessageMe™ <br /> Extension
              </h1>
            </div>

            <div className="d-flex align-items-center pb-2 pl-4 pt-4">
              <IoCheckmarkCircle size={20} color="#47be9b" className="mr-1" />{" "}
              {isSuperAdmin === true && !orgDetails?.is_webapp_enabled ? (
                <p style={{ fontSize: "13px" }}>
                  This will activate the Key Management Service
                  <br /> in your AWS Account.
                </p>
              ) : (
                <p style={{ fontSize: "13px" }}>
                  You'll be able to access the extended
                  <br /> version soon.
                </p>
              )}
            </div>

            {/* make it pb-4 when u enable kms features */}
            <div className="d-flex align-items-center pl-4 pb-2">
              <IoCheckmarkCircle size={20} color="#47be9b" className="mr-1" />{" "}
              <p style={{ fontSize: "13px" }}>
                You'll be able to access the extended
                <br /> version soon.
              </p>
            </div>

            {/* <div className="d-flex align-items-center flex-col pt-4 pb-2 bg-red">
            </div> */}

            {isSuperAdmin === false ? (
              <p
                style={{
                  color: "red",
                  // marginLeft: "16px",
                  fontSize: "12px",
                }}
                className="pl-4"
              >
                You can not enable this as you are not super admin.
              </p>
            ) : orgDetails.is_webapp_enabled ? (
              <div className="d-flex align-items-center pb-2 justify-content-end pr-6">
                {orgDetails.is_webapp_enabled ? (
                  <Button
                    color="danger"
                    size="sm"
                    style={{
                      marginLeft: "14px",
                      backgroundColor: "#ffb900",
                      borderColor: "#ffb900",
                      outline: "none !important",
                      outlineOffset: "none !important",
                      boxShadow: "none",
                      borderRadius: "10px",
                      width: "85px",
                    }}
                    onClick={disableKMSToggle}
                  >
                    Disable
                  </Button>
                ) : isSuperAdmin === false ? (
                  <p
                    style={{
                      color: "red",
                      // marginLeft: "16px",
                      fontSize: "12px",
                    }}
                    className="pl-4"
                  >
                    You can not enable this as you are not super admin.
                  </p>
                ) : (
                  <Button
                    onClick={handleClick}
                    style={{
                      text: "white",
                      backgroundColor: "#47BE9B",
                      borderColor: "#47BE9B",
                      borderRadius: "10px",
                      width: "85px",
                      outline: "none !important",
                      outlineOffset: "none !important",
                      boxShadow: "none",
                    }}
                    size="sm"
                    disabled={disable}
                  >
                    Enable
                  </Button>
                )}
              </div>
            ) : !orgDetails.is_webapp_enabled && isSuperAdmin === true ? (
              <div className="d-flex align-items-center pb-2 justify-content-around">
                <h1
                  style={{ fontSize: "13px", color: "#585858", width: "50%" }}
                >
                  Deactivated on {istConversion(orgDetails.lastDeleteOrgKeys)}
                </h1>
                {symId && asymId && isSuperAdmin === true ? (
                  <Button
                    color="danger"
                    size="sm"
                    style={{
                      marginLeft: "14px",
                      backgroundColor: "#ffb900",
                      borderColor: "#ffb900",
                      outline: "none !important",
                      outlineOffset: "none !important",
                      boxShadow: "none",
                      borderRadius: "10px",
                      width: "85px",
                    }}
                    onClick={handleClickDisableKMS}
                  >
                    Disable
                  </Button>
                ) : isSuperAdmin === false ? (
                  <p
                    style={{
                      color: "red",
                      // marginLeft: "16px",
                      fontSize: "13px",
                    }}
                    className="pl-4"
                  >
                    You can not enable this as you are not super admin.
                  </p>
                ) : (
                  <Button
                    onClick={handleClick}
                    style={{
                      text: "white",
                      backgroundColor: "#47BE9B",
                      borderColor: "#47BE9B",
                      borderRadius: "10px",
                      width: "85px",
                      outline: "none !important",
                      outlineOffset: "none !important",
                      boxShadow: "none",
                    }}
                    size="sm"
                    disabled={disable}
                  >
                    Enable
                  </Button>
                )}
              </div>
            ) : (
              <div className="d-flex align-items-center pb-2 justify-content-around">
                {isSuperAdmin === true && (
                  <h1
                    style={{ fontSize: "13px", color: "#585858", width: "50%" }}
                  >
                    Activated on {istConversion(orgDetails.lastUpdateOrgKeys)}
                  </h1>
                )}

                {symId && asymId ? (
                  <Button
                    size="sm"
                    style={{
                      marginLeft: "14px",
                      cursor: "not-allowed",
                      backgroundColor: "#ffb900",
                      borderColor: "#ffb900",
                      outline: "none !important",
                      outlineOffset: "none !important",
                      boxShadow: "none",
                      borderRadius: "10px",
                      width: "85px",
                    }}
                    onClick={handleClickDisableKMS}
                    disabled={isSuperAdmin === false}
                  >
                    Disable
                  </Button>
                ) : isSuperAdmin === false ? (
                  <p
                    style={{
                      color: "red",
                      // marginLeft: "16px",
                      fontSize: "13px",
                    }}
                    className="pl-4"
                  >
                    You can not enable this as you are not super admin.
                  </p>
                ) : (
                  <Button
                    onClick={handleClick}
                    style={{
                      text: "white",
                      backgroundColor: "#47BE9B",
                      borderColor: "#47BE9B",
                      borderRadius: "10px",
                      width: "85px",
                      outline: "none !important",
                      outlineOffset: "none !important",
                      boxShadow: "none",
                    }}
                    size="sm"
                    disabled={disable}
                  >
                    Enable
                  </Button>
                )}
              </div>
            )}
            {/* <div className="d-flex align-items-center pb-2 justify-content-end pr-4">
              <Button
                // color="danger"
                size="sm"
                style={{
                  marginLeft: "14px",
                  backgroundColor: "#ffb900",
                  borderColor: "#ffb900",
                  outline: "none !important",
                  outlineOffset: "none !important",
                  boxShadow: "none",
                  borderRadius: "10px",
                  width: "105px",
                }}
              >
                Coming Soon
              </Button>
            </div> */}
          </div>
        </div>

        {/* OLD UI AGAIN */}
        {/* FIRST CARD */}
        {/*
        
                
                 <Container className="mt-0 pt-1">
          <Row>
            <div className="col-12 col-md-8 col-lg-8 p-0">
              <div
                style={{
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
                  borderRadius: "8px",
                  // width: "65%",
                  border: "1px solid rgba(0,0,0,0.125)",
                }}
                ref={fslRef}
                className="pl-4 pt-2 pr-2 d-flex align-items-center justify-content-between mb-4"
              >
                <div
                  className="d-flex align-items-start"
                  style={{
                    width: "15px",
                    height: `${heightFsl + "px"}`,
                    background: " #FAAE99",
                    borderRadius: "4px 0px 0px 4px",
                    position: "absolute",
                    marginLeft: "-24px",
                    marginTop: "-8px",
                  }}
                ></div>
                <div className="d-flex flex-col mb-4">
                  <div
                    style={{ gap: "8px" }}
                    className="d-flex align-items-center"
                  >
                    {/* <div
                style={{
                  position: "absolute",
                  width: "15px",
                  height: "156px",
                  left: "23px",
                  top: "293px",
                  background: " #FAAE99",
                  borderRadius: "4px 0px 0px 4px",
                }}
              ></div> */}

        {/* <div className="number bg-meme">1</div> -- old ui */}
        {/* <AiFillFile
                      style={{ width: "1.5rem", height: "1.5rem" }}
                      color="#74798C"
                    />
                    <h3 style={{ color: "#74798C" }}>
                      <b>File size Limiter</b>
                    </h3>
                  </div>
                  <div style={{ marginLeft: "33px" }}>
                    <p style={{ color: "#74798C" }}>
                      Set a pre-defined limit to files being shared over
                    </p>
                    <div
                      style={{
                        gap: "12px",
                      }}
                      className="d-flex align-items-center justify-content-between mb-2 mt-2 "
                    >
                      <p
                        style={{ color: "#74798C" }}
                        className="d-flex align-items-center font-bold"
                      >
                        Current Limit:
                      </p>
                      <div className="">
                        {isSuperAdmin === true || permission.fsl === true ? (
                          <FormControl variant="filled" size="small">
                            <Select
                              value={selectValue}
                              disableUnderline
                              style={{ paddingBottom: "12px" }}
                              displayEmpty
                              onChange={(e) => setSelectValue(e.target.value)}
                            >
                              <MenuItem value={2}>2MB</MenuItem>
                              <MenuItem value={10}>10MB</MenuItem>
                              <MenuItem value={50}>50MB</MenuItem>
                              <MenuItem value={200}>200MB</MenuItem>
                            </Select>
                          </FormControl>
                        ) : (
                          <p
                            className=" font-weight-bold d-flex align-items-center mb-0"
                            style={{ color: "gray" }}
                          >
                            {fsl} MB
                          </p>
                        )}
                      </div>
                      <Button
                        type="button"
                        style={{
                          color: "#fff",
                          backgroundColor: "#FAAE99",
                          width: "85px",
                          borderColor: "#FAAE99",
                          outline: "none !important",
                          outlineOffset: "none !important",
                          boxShadow: "none",
                        }}
                        size="sm"
                        // className={`${
                        //   isSuperAdmin === false
                        //     ? "cursor-not-allowed bg-meme"
                        //     : "bg-meme"
                        // }`}
                        disabled={
                          isSuperAdmin === false ? permission.fsl === false : ""
                        }
                        onClick={() => handleFileChange(parseInt(selectValue))}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                  {isSuperAdmin === true ? (
                    " "
                  ) : permission.fsl === false ? (
                    <p style={{ color: "red", marginLeft: "40px" }}>
                      Restricted as permission has not been granted by the
                      designated super administrator.
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <img src={fslImage} />
              </div>
            </div>
          </Row>
        </Container> */}

        {/* SECOND CARD */}
        {/* <Container className="mt-2">
          <Row>
            <div className="col-12 col-md-8 col-lg-8 p-0">
              <div
                style={{
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
                  borderRadius: "8px",
                  // width: "65%",
                  border: "1px solid rgba(0,0,0,0.125)",
                }}
                ref={ref}
              >
                <div
                  className="d-flex align-items-start"
                  style={{
                    width: "15px",
                    height: `${height + "px"}`,
                    background: " #47BE9B",
                    borderRadius: "4px 0px 0px 4px",
                    position: "absolute",
                  }}
                ></div>
                <div
                  style={{ gap: "8px" }}
                  className="d-flex align-items-center pl-4 pt-2"
                >
                  {/* <div
              style={{
                position: "absolute",
                width: "15px",
                height: "241px",
                left: "23px",
                top: "473px",
                background: " #47BE9B",
                borderRadius: "4px 0px 0px 4px",
              }}
            ></div> */}

        {/* <BiWorld
                    style={{ width: "1.5rem", height: "1.5rem" }}
                    color="#74798C"
                  />
                  <h3 style={{ color: "#74798C" }}>
                    <b>Enable Web Application</b>
                  </h3>
                </div>
                <div
                  style={{
                    marginLeft: "65px",
                  }}
                >
                  <div style={{ lineHeight: "40px" }}>
                    <li style={{ color: "grey" }}>
                      Employees can get access to <b>MessageMe</b> Web-App after
                      enabling it here.
                    </li>
                    <div className="d-flex  justify-content-around">
                      <div className="w-100">
                        <li style={{ color: "grey" }}>
                          Your private messaging platform.
                        </li>

                        {/* security, monitor, control */}
        {/* <div
                          style={{
                            display: "flex",
                            color: "black",
                            gap: "26px",
                            marginLeft: "18px",
                            marginBottom: "8px",
                            paddingBottom: "15px",
                          }}
                        >
                          <h1>
                            <b>Security</b>{" "}
                          </h1>
                          <h1>
                            <b>Monitor</b>{" "}
                          </h1>
                          <h1>
                            <b>Control</b>
                          </h1>
                        </div> */}

        {/* {symId && asymId ? (
                          <Button
                            color="danger"
                            size="sm"
                            disabled
                            style={{
                              marginLeft: "14px",
                              cursor: "not-allowed",
                              backgroundColor: "#47BE9B",
                              borderColor: "#47BE9B",
                              outline: "none !important",
                              outlineOffset: "none !important",
                              boxShadow: "none",
                            }}
                          >
                            Enabled
                          </Button>
                        ) : isSuperAdmin === false ? (
                          <p style={{ color: "red", marginLeft: "14px" }}>
                            You can not enable this as you are not super admin.
                          </p>
                        ) : (
                          <Button
                            onClick={handleClick}
                            style={{
                              text: "white",
                              backgroundColor: "#47BE9B",
                              border: "none",
                              borderColor: "none",
                            }}
                            size="sm"
                            disabled={disable}
                          >
                            Enable
                          </Button>
                        )}
                      </div>
                      <img
                        alt="appSettings"
                        src={
                          require("assets/img/illustrations/integration1.png")
                            .default
                        }
                        style={{ width: "30%", height: "40%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container> */}
      </div>

      {/* <hr
        style={{
          border: "0",
          height: "1px",
          background: "#D3D3D3",
        }}
      /> */}
    </>
  );
};

export default Integrations;
