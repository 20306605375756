import React, { useState } from "react";
import "./styles/randomFunctions.css";
import { useLocation } from "react-router-dom";
import { FormGroup } from "reactstrap";
import { AUTH_ROUTE_PATHS } from "routes-constants";

const ValidatedInput = ({
  label,
  onChange,
  onBlur,
  value,
  isValid,
  placeholder,
  errorMessage,
  clearError,
  type,
}) => {
  const location = useLocation();
  console.log(location);

  const inputStyle = {
    backgroundColor: "#EDF2F7",
    border: isValid ? "none" : "2px solid red",
  };

  const labelStyle = {
    color: "#455768",
    backgroundColor: "#EDF2F7",
  };

  return (
    <FormGroup className="form-group">
      <div className="floating-label">
        <input
          className={`${location.pathname === `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`
            ? "form-control overrideFormPadding"
            : "form-control"
            }`}
          type={type}
          style={inputStyle}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          onFocus={clearError}
          placeholder={placeholder}
          required
        />
        {/* <label style={labelStyle}>{label}</label> */}
        {!isValid && errorMessage && (
          <div style={{ color: "red", fontSize: "12px" }}>{errorMessage}</div>
        )}
      </div>
    </FormGroup>
  );
};

export default ValidatedInput;
