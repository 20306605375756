import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Toast from "react-bootstrap/Toast";
import { Link, Redirect } from "react-router-dom";
import { sha256 } from "js-sha256";
import customToast from "./ToastComponent";

// import {HiArrowRight} from 'react-icons/hi'
import logo from "../../assets/MeMe White Logo.png";
import banner from "../../assets/Sign In Illustration.svg";
import banner1 from "../../assets/Sign In Illustration – 2.svg";
import invite from "../../assets/Invite.png";

import "../../assets/css/bootstrap.css";
import "../../assets/css/login.css";
import loginPg from "../../assets/login.svg";
import loginPg1 from "../../assets/login1.svg";
import loginPg2 from "../../assets/login2.svg";

// ----------------------------------------------------------Employee Invite Part
import Basic from "./EmailInvite.js";

import CardMedia from "@material-ui/core/CardMedia";
import { Card, CardContent, Typography } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// -------------------------------------------------------------------------------

// reactstrap components
import {
  Button,
  //Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  Row,
  Col,
} from "reactstrap";
import {
  getTwoFa,
  login,
  loginCheck,
  sendOtpFor2FA,
  verifyRecaptcha,
  validateLogin,
  sendOtp,
} from "../../network/ApiAxios";
import memeLogo from "../../assets/img/icons/common/memeLogo.png";
import { RotatingLines } from "react-loader-spinner";
import ValidatedInput from "functions/ValidatedInput";
// import ReCAPTCHA from "react-google-recaptcha";
import FAQ from "./FAQ";
import Banner from "../ReusableComponents/Banner";
import { useDispatch } from "react-redux";
import { setUserId } from "components/redux/adminActions";
import { AUTH_ROUTE_PATHS } from "routes-constants";

const Login = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [activeChild, setActiveChild] = useState(0);
  const [password, setPassword] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);

  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(
    "Email sent! Check it to reset your password."
  );
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [emails, setInviteEmails] = useState([]);
  // const [showRecaptcha, setShowRecaptcha] = useState(false);
  // const [recaptchaChecked, setRecaptchaChecked] = useState(false);
  const passwordInputRef = useRef(null);

  const items = useMemo(() => [1, 2, 3, 4], []);

  const changeChild = useCallback(
    (e) => {
      if (e.key === "ArrowLeft") {
        // If supposed previous child is < 0 set it to last child
        setActiveChild((a) => (a - 1 < 0 ? items.length - 1 : a - 1));
      } else if (e.key === "ArrowRight") {
        // If supposed next child is > length -1 set it to first child
        setActiveChild((a) => (a + 1 > items.length - 1 ? 0 : a + 1));
      }
    },
    [items]
  );

  useEffect(() => {
    document.addEventListener("keydown", changeChild);

    return function cleanup() {
      document.removeEventListener("keydown", changeChild);
    };
  });

  const tryLogin = async () => {
    try {
      if (!(email && password)) {
        setError("Make sure to fill all the inputs");
        setTimeout(() => {
          setError("");
        }, 3500);
        return;
      }
      var hash = sha256(password);
      const response = await login(email, hash);
      console.log("Navigated" + response);
      const { data } = response;
      if (data.success) {
        setError("");
        setToastMessage("Login Successful");
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("loginTime", JSON.stringify(data.loginTime));
        localStorage.setItem("expiryDate", JSON.stringify(data.expiryDate));
        localStorage.setItem("twoFa", JSON.stringify(data.user.twoFA));
        if (data.showPopup) {
          localStorage.setItem("showPopup", JSON.stringify(data.showPopup));
        }
        props.history.push("/");
      } else {
        setPassword("");
        setToastMessage(data.msg);
        setError(data.msg);
        setLoggedIn(false);
        setTimeout(() => {
          setError("");
          setToastMessage("");
        }, 3500);
      }
      setShowToast(true);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle the rate limit exceeded error
        setError("Too many wrong attempts, try again later after 10 minutes");
        setTimeout(() => {
          setError("");
        }, 3500);
        setLoggedIn(false);
      } else {
        // Handle other errors
        setError(error ? error : "An error occurred.");
        setTimeout(() => {
          setError("");
        }, 3500);
        setLoggedIn(false);
      }
    }
  };

  const handleClick = async (event) => {
    try {
      setLoggedIn(true);
      event.preventDefault();
      var hash = sha256(password);
      const response = await loginCheck(email, hash);
      console.log(response, "handleClick");
      // if (response.data.showRecaptcha === true) {
      //   setShowRecaptcha(true);
      //   return;
      // }
      if (response.data.accountConfirmation === false) {
        const { data } = await sendOtp(response.data.id);
        dispatch(setUserId(response.data.id));
        if (data.success === true) {
          // console.log(data);
          customToast(
            true,
            "",
            "",
            "OTP sent",
            "Kindly check your registered mail address and enter the OTP",
            "Check Email",
            ""
          );
        }

        props.history.push({
          pathname: `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.OTP}`,
          customNameData: response.data.id,
          email: email,
          accountConfirmation: false,
        });
      }
      if (response.data.success === false) {
        setError(response.data.msg);
        setLoggedIn(false);
        setTimeout(() => {
          setError("");
        }, 3500);
      } else {
        const { next, userId, token } = response.data;
        if (next === true) {
          var hash1 = sha256(password);
          dispatch(setUserId(userId));
          const resp = await sendOtpFor2FA(userId);
          props.history.push({
            pathname: `${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.TWO_FA_OTP}`,
            customNameData: userId,
            password: hash1,
            email: email,
          });
        } else {
          const data1 = await validateLogin(userId, token);
          console.log(data1, "data 1 validated login");
          if (data1.data.success === true) {
            setToastMessage("Login Successful");
            localStorage.setItem("token", data1.data.token);
            localStorage.setItem("user", JSON.stringify(data1.data.user));
            localStorage.setItem(
              "twoFa",
              JSON.stringify(data1.data.user.twoFA)
            );
            localStorage.setItem(
              "loginTime",
              JSON.stringify(data1.data.loginTime)
            );
            localStorage.setItem(
              "expiryDate",
              JSON.stringify(data1.data.expiryDate)
            );
            localStorage.setItem("ip", JSON.stringify(data1.data.ip));
            if (data1.data.showPopup) {
              localStorage.setItem(
                "showPopup",
                JSON.stringify(data1.data.showPopup)
              );
            }
            // console.log(data);
            props.history.push("/");
          } else {
            setError("Something went wrong");
            setTimeout(() => {
              setError("");
            }, 3500);
            setLoggedIn(false);
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // Handle the rate limit exceeded error
        setError("Too many wrong attempts, try again later after 10 minutes");
        setTimeout(() => {
          setError("");
        }, 3500);
        setLoggedIn(false);
      } else {
        // Handle other errors
        console.log("error ", error);
        setError(error ? error : "An error occurred.");
        setTimeout(() => {
          setError("");
        }, 3500);
        setLoggedIn(false);
      }
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    // Email validation rules
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailPattern.test(newEmail);
    setIsEmailValid(isValid);
    setEmailErrorMessage(isValid ? "" : "Invalid email format.");
  };

  const handleEmailBlur = () => {
    // Email validation rules
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsEmailValid(emailPattern.test(email));
    if (isEmailValid) {
      setEmailErrorMessage("");
    } else {
      setEmailErrorMessage("Invalid email format.");
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    // setIsPasswordTouched(true);
    // Password validation rules
    const hasValidLength = newPassword.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(newPassword);
    const hasNumber = /[0-9]/.test(newPassword);
    const isValid = hasValidLength && hasAlphabet && hasNumber;
    setIsPasswordValid(isValid);
    setPasswordErrorMessage(
      isValid
        ? ""
        : newPassword.length === 0
          ? ""
          : "Password must be 8+ chars with num & special symbol."
    );
  };

  // const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  // useEffect(() => {
  //   // Password validation rules
  //   const hasValidLength = password.length >= 8;
  //   const hasAlphabet = /[a-zA-Z]/.test(password);
  //   const hasNumber = /[0-9]/.test(password);
  //   const isPasswordValid = hasValidLength && hasAlphabet && hasNumber;

  //   setIsPasswordValid(isPasswordValid);
  //   setPasswordErrorMessage(
  //     isPasswordValid
  //       ? ""
  //       : password.length === 0
  //       ? ""
  //       : "Password must be 8+ chars with num & special symbol."
  //   );
  // }, [password]);
  // console.log(isPasswordValid, "isPass");

  const handlePasswordBlur = () => {
    // Password validation rules
    const hasValidLength = password.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    setIsPasswordValid(hasValidLength && hasAlphabet && hasNumber);
    setPasswordErrorMessage(
      isPasswordValid
        ? ""
        : password.length === 0
          ? ""
          : "Password must be 8+ chars with num & special symbol."
    );
  };

  // const clearPasswordErrors = () => {
  //   setIsPasswordValid(true);
  // };

  // useEffect(() => {
  //   const f = async () => {
  //     if (recaptchaChecked) {
  //       const response = await verifyRecaptcha(recaptchaChecked);
  //       console.log(response, "verifyRecap");
  //       if (response.data.success === true) {
  //         setLoggedIn(false);
  //         setShowRecaptcha(false);
  //         setRecaptchaChecked(false);
  //       }
  //     }
  //   };
  //   f();
  // }, [recaptchaChecked, loggedIn]);
  // console.log(loggedIn, "loggedIn");
  const handleRecaptchaChange = async (value) => {
    // console.log(value, "recaptcha");
    if (value) {
      const response = await verifyRecaptcha(value); // Send verification only when checkbox is checked
      console.log(response, "verifyRecap");
      if (response.data.success === true) {
        setLoggedIn(false);
        // setShowRecaptcha(false);
        // setRecaptchaChecked(true);
      }
    }
  };
  // console.log(recaptchaChecked, "check");

  return (
    <>
      <Banner />
      <FAQ />
      <section className="">
        <div className="fixed smallGreenCircle d-none d-lg-block "></div>
        <div className="fixed loginGreenCircle d-none d-lg-block "></div>
        <div className="fixed loginGreenCircleHollow d-none d-lg-block "></div>

        <div className="container mt-20 py-md-0 mt-md-4 mb-md-4 mb-20">
          <div className="row d-flex justify-content-center align-items-center mx-auto">
            <div className="col col-xl-10">
              <div
                className="card shadow-xl shadow-intensity-xl shadow shadow-offset-down-lg shadow-offset-left-lg cardShadow"
                style={{
                  borderRadius: "0.7em",
                }}
              >
                <div className="row g-0">
                  <div className="col-md-6 d-none d-md-block overflow-hidden loginImage1"></div>
                  <div className="col-md-6 d-flex align-items-center">
                    <div className="card-body text-black">
                      <img
                        src={memeLogo}
                        alt="logo"
                        style={{
                          top: "7px",
                          position: "absolute",
                          right: "2px",
                          paddingRight: "20px",
                          width: "166.6px",
                          height: "40.45px",
                        }}
                      />
                      <h3 className="h3heading text-center mt-5">
                        Sign in to Admin Panel
                      </h3>
                      <br></br>
                      <Form
                        role="form"
                        onSubmit={(values) => {
                          handleClick(values);
                        }}
                      >
                        <label style={{ color: "#585858", display: "flex" }}>
                          <strong>E-mail</strong>
                        </label>
                        <ValidatedInput
                          label="Email"
                          onChange={handleEmailChange}
                          onBlur={handleEmailBlur}
                          onFocus={() => setIsEmailValid(true)}
                          value={email}
                          isValid={isEmailValid}
                          placeholder="Enter your email"
                          errorMessage={emailErrorMessage}
                          type="email"
                        />
                        <label style={{ color: "#585858", display: "flex" }}>
                          <strong>Password</strong>
                        </label>
                        <ValidatedInput
                          label="Password"
                          onChange={handlePasswordChange}
                          onBlur={handlePasswordBlur}
                          onFocus={() => setIsPasswordValid(true)}
                          value={password}
                          isValid={isPasswordValid}
                          placeholder="Enter your password"
                          errorMessage={passwordErrorMessage}
                          type="password"
                        // ref={passwordInputRef?.current}
                        />
                        <div style={{ margin: "1rem 0" }}>
                          <label style={{ color: "#585858", display: "flex" }}>
                            <strong>Captcha</strong>
                          </label>
                          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                            <img
                              src="/path-to-captcha-image"
                              alt="Captcha Added"
                              style={{ border: "1px solid #ddd", borderRadius: "4px" }}
                            />
                            <ValidatedInput
                              label="Captcha"
                              // onChange={handleCaptchaChange}
                              // value={captcha}
                              // isValid={isCaptcha`Valid}
                              placeholder="Enter captcha"
                              // errorMessage={captchaErrorMessage}
                              type="text"
                            />
                          </div>
                        </div>
                        {error ? (
                          <div className="text-muted">
                            <small>
                              {" "}
                              <span className="text-red font-weight-900">
                                {error}
                              </span>
                            </small>
                          </div>
                        ) : null}
                        <div className="d-md-flex justify-content-between ">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                              style={{ color: "#585858" }}
                            >
                              Remember Me
                            </label>
                          </div>
                          <Link
                            to={AUTH_ROUTE_PATHS.AUTH_LAYOUT + AUTH_ROUTE_PATHS.RESET_PASSWORD}
                            className="inline-block-child forgettext"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                        {/* {showRecaptcha && (
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                            onChange={handleRecaptchaChange}
                          />
                        )} */}
                        <div className="rowkey">
                          <Button
                            className={`${emailErrorMessage ||
                              passwordErrorMessage ||
                              loggedIn
                              ? "inline-block-child signIn cursor-not-allowed"
                              : "inline-block-child signIn"
                              }`}
                            color="primary"
                            type="submit"
                            disabled={
                              emailErrorMessage ||
                              passwordErrorMessage ||
                              loggedIn ||
                              !isEmailValid ||
                              !isPasswordValid
                            }
                          >
                            {loggedIn === false ? (
                              "Log In"
                            ) : (
                              <div className="d-flex align-items-center justify-content-center">
                                <RotatingLines
                                  strokeColor="white"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="26"
                                  height="26"
                                  visible={true}
                                />
                              </div>
                            )}
                          </Button>
                        </div>
                      </Form>
                      <div className="d-flex align-items-center justify-content-center">
                        <hr className="hr1" />
                      </div>
                      <br />
                      <div className="rowkey">
                        <a
                          className="inline-block-child needaccounttext text-center "
                          onClick={() => props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.REGISTER}`)}
                        >
                          Need an Account?
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-around align-items-center mt-2">
            <p style={{ color: "#868585" }}>
              &#169; 2023 - Arishti CyberTech Pvt. Ltd. All rights reserved
            </p>
            <a
              className="font-bold"
              style={{
                color: "#ffffff",
                textDecoration: "none",
                zIndex: "99",
                fontWeight: "600",
              }}
              href="https://arishti.in/privacy.html"
              target="_blank"
            >
              Privacy Policies & Terms
            </a>
          </div>
        </div>
      </section>
      <div
        className="p-3 my-2 rounded"
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "fixed",
          minHeight: "100px",
          width: "15%",
          right: 10,
          top: 50,
          zIndex: 50,
        }}
      >
        <Toast
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: 20,
            backgroundColor: "white",
          }}
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header
            style={{
              color: "#3107CB",
              padding: 5,
              icon: "danger",
            }}
          >
            <img
              style={{ height: "30px", width: "100px" }}
              src={require("assets/img/brand/message-me.png").default}
            />
          </Toast.Header>
          <Toast.Body
            style={{
              color: "red",
              padding: 5,
            }}
          >
            {toastMessage}
          </Toast.Body>
        </Toast>
      </div>
    </>
  );
};
export default Login;
