import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import FormComponent from "./FormComponent";
import H6 from "@material-tailwind/react/Heading6";
import Cards from "@material-tailwind/react/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import dashboardImage from "../../assets/carousel/new_admin_dashboard.jpeg";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { useState } from "react";
import AttachFile from "@material-ui/icons/AttachFile";
import { receiveFeedback } from "network/ApiAxios";
import { ToastContainer, Zoom, toast } from "react-toastify";
import "../../assets/css/toast.css";
import customToast from "views/examples/ToastComponent";
import { ADMIN_ROUTE_PATHS } from "routes-constants";

const useStyles = makeStyles({
  box: {
    padding: "10px",
    position: "absolute",
    top: "20px",
  },
});

const Feedback = ({ openPopUp, setOpenPopUp }) => {
  const classes = useStyles();
  const newAdmin = () => setAdminModal(!adminModal);
  const [adminModal, setAdminModal] = useState(false);

  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("user"))?.email
  );
  const [para, setPara] = useState("");
  const [name, setName] = useState(
    JSON.parse(localStorage.getItem("user"))?.name
  );
  const [error, setError] = useState("");
  const [disable, setDisable] = useState(false);
  const [attach, setAttach] = useState();

  const handleClose = () => {
    setOpenPopUp(false);
  };

  // 	newAdmin();
  // 	try {
  // 		console.log("hola", email);

  // 		const AdminEmail = JSON.parse(localStorage.getItem("user"))?.email;
  // 		const orgToken = JSON.parse(localStorage.getItem("user"))?.orgToken;
  // 		var returnedEmail = "";
  // 		// checking for if entered email already exists
  // 		console.log(email, "email");
  // 		await checkNewAdminEmail(email).then((res) => {
  // 			console.log(res, "resss log");
  // 			if (res.data.success === 1) {
  // 				toast.error(
  // 					<span>
  // 						<i className='fas fa-times'></i>&nbsp;&nbsp;{res.data.message}
  // 					</span>,
  // 					{
  // 						position: "top-right",
  // 						autoClose: 5000,
  // 						hideProgressBar: false,
  // 						closeOnClick: true,
  // 						pauseOnHover: true,
  // 						draggable: true,
  // 						progress: undefined,
  // 					}
  // 				);
  // 			} else if (res.data.success === true) {
  // 				toast.success(
  // 					<span>
  // 						<i className='fas fa-times'></i>&nbsp;&nbsp;{res.data.message}
  // 					</span>,
  // 					{
  // 						position: "top-right",
  // 						autoClose: 5000,
  // 						hideProgressBar: false,
  // 						closeOnClick: true,
  // 						pauseOnHover: true,
  // 						draggable: true,
  // 						progress: undefined,
  // 					}
  // 				);
  // 			}
  // 			returnedEmail = res.data.email;
  // 		});

  // 		// sends email to new users
  // 		if (returnedEmail.length > 0) {
  // 			await sendAdminInvite(returnedEmail, AdminEmail, orgToken)
  // 				.then((res) => {
  // 					if (res.data.success === 2) {
  // 						console.log("ressstruee", res);
  // 						toast.success(
  // 							<span>
  // 								<i className='fas fa-check'></i>&nbsp;&nbsp;
  // 								{res.data.message}
  // 							</span>,
  // 							{
  // 								position: "top-right",
  // 								autoClose: 5000,
  // 								hideProgressBar: false,
  // 								closeOnClick: true,
  // 								pauseOnHover: true,
  // 								draggable: true,
  // 								progress: undefined,
  // 							}
  // 						);
  // 					} else if (res.data.success === 1) {
  // 						console.log("resss111", res);
  // 						toast.error(
  // 							<span>
  // 								<i className='fas fa-times'></i>&nbsp;&nbsp;
  // 								{res.data.message}
  // 							</span>,
  // 							{
  // 								position: "top-right",
  // 								autoClose: 5000,
  // 								hideProgressBar: false,
  // 								closeOnClick: true,
  // 								pauseOnHover: true,
  // 								draggable: true,
  // 								progress: undefined,
  // 							}
  // 						);
  // 					} else {
  // 						toast.error(
  // 							<span>
  // 								<i className='fas fa-times'></i>&nbsp;&nbsp;Failed to send
  // 								Email
  // 							</span>,
  // 							{
  // 								position: "top-right",
  // 								autoClose: 5000,
  // 								hideProgressBar: false,
  // 								closeOnClick: true,
  // 								pauseOnHover: true,
  // 								draggable: true,
  // 								progress: undefined,
  // 							}
  // 						);
  // 					}
  // 				})
  // 				.catch(() => {
  // 					toast.error(
  // 						<span>
  // 							<i className='fas fa-times'></i>&nbsp;&nbsp;Something Went
  // 							Wrong!
  // 						</span>,
  // 						{
  // 							position: "top-right",
  // 							autoClose: 5000,
  // 							hideProgressBar: false,
  // 							closeOnClick: true,
  // 							pauseOnHover: true,
  // 							draggable: true,
  // 							progress: undefined,
  // 						}
  // 					);
  // 				});
  // 		}

  // 		setEmail("");
  // 	} catch (error) {
  // 		toast.error(
  // 			<span>
  // 				<i className='fas fa-times'></i>&nbsp;&nbsp;Failed to send email!
  // 			</span>,
  // 			{
  // 				position: "top-right",
  // 				autoClose: 5000,
  // 				hideProgressBar: false,
  // 				closeOnClick: true,
  // 				pauseOnHover: true,
  // 				draggable: true,
  // 				progress: undefined,
  // 			}
  // 		);
  // 	}
  // };

  let base64Code = "";
  // onChange function for uploading
  const uploadImage = (e) => {
    const file = e.target.files[0];
    console.log("type", file.type, file.size);
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/jpg"
    ) {
      setError(`${file.name} format is not supported.`);
      return;
    } else if (file.size > 1024 * 1024 * 5) {
      setError(`${file.name} is too large max 5MB allowed.`);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      onLoad(reader.result);
      // setImage(reader.result);
    };
  };

  const onLoad = (fileString) => {
    base64Code = fileString;
    setAttach(base64Code);
    // console.log(base64Code, 'onLoad');
  };

  const sendFeedback = async () => {
    try {
      const res = await receiveFeedback(name, email, para, attach);
      if (res.success === true) {
        customToast(
          true,
          "",
          "",
          "Thank you for your feedback!",
          "Our team will get back to you at the earliest possible",
          "Go to Dashboard",
          `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`,
          ""
        );
        handleClose();
      } else if (res.success === false) {
        customToast(
          "",
          true,
          "",
          "Error occurred while submitting feedback",
          "Try submitting again",
          "Go to Dashboard",
          `${ADMIN_ROUTE_PATHS.ADMIN_LAYOUT}${ADMIN_ROUTE_PATHS.INDEX}`,
          ""
        );
        handleClose();
      }
    } catch (error) { }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal
        isOpen={openPopUp}
        toggle={newAdmin}
        centered
        size="md"
        style={{
          height: "200px",
        }}
      >
        <Cards
          style={{
            height: "150px",
          }}
        >
          <Image
            style={{
              marginTop: "5px",
              width: "100%",
              height: "50% !important",
            }}
            src={
              dashboardImage
            }
            alt="Card Image"
          />
          {/* <CardBody> */}
          <H6 color="gray">Please give your feedback</H6>
          {/* <br></br> */}
          <Form role="form">
            <FormGroup className=" form-group">
              <div className="floating-label">
                <input
                  id="name"
                  className="form-control"
                  type="text"
                  name="name"
                  style={{
                    backgroundColor: "#EDF2F7",
                    border: "none",
                  }}
                  value={name}
                  required
                />
              </div>
            </FormGroup>
            <FormGroup className=" form-group">
              <div className="floating-label">
                <input
                  id="email"
                  className="form-control"
                  type="email"
                  style={{
                    backgroundColor: "#EDF2F7",
                    border: "none",
                  }}
                  name="email"
                  value={email}
                  required
                />
              </div>
            </FormGroup>
            <FormGroup className=" form-group">
              <div className="floating-label">
                <Label
                  for="first"
                  style={{
                    color: "#455768",
                  }}
                >
                  Feedback
                </Label>
                <Input
                  id="exampleText"
                  className="form-control"
                  type="textarea"
                  style={{
                    backgroundColor: "#EDF2F7",
                    border: "none",
                  }}
                  name="text"
                  onChange={(e) => setPara(e.target.value)}
                  value={para}
                  required
                />
              </div>
            </FormGroup>

            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={uploadImage}
            />
            <label htmlFor="raised-button-file" className={classes.inputs}>
              <span variant="raised" style={{ color: "#455768" }}>
                <AttachFile
                  className={classes.fileAttach}
                  style={{ color: "black", cursor: "pointer" }}
                />{" "}
                {attach ? "Attached File" : "Attach File"}
              </span>
            </label>

            {error ? (
              <div className="text-muted">
                <small>
                  {" "}
                  <span className="text-red font-weight-700">{error}</span>
                </small>
              </div>
            ) : null}
          </Form>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginLeft: "20px",
            }}
            className="btns"
          >
            {para.length === 0 ? (
              <Button
                disabled
                variant="primary"
                style={{ width: "100%", borderRadius: "50px" }}
              >
                Send
              </Button>
            ) : (
              <Button
                // disabled={!disable}
                onClick={sendFeedback}
                variant="primary"
                style={{ width: "100%", borderRadius: "50px" }}
              >
                Send
              </Button>
            )}
            <Button
              onClick={handleClose}
              variant="light"
              style={{ width: "100%", borderRadius: "50px" }}
            >
              Cancel
            </Button>
          </div>
        </Cards>
      </Modal>
    </>
  );
};

export default Feedback;
