const PREFIX = process.env.REACT_APP_ON_PREMISE === "true" ? process.env.REACT_APP_ON_PREMISE_PREFIX || "" : "";

export const AUTH_ROUTE_PATHS = {
    AUTH_LAYOUT: `${PREFIX}/auth`,
    LOGIN: `/login`,
    REGISTER: `/register`,
    NEW_ADMIN: `/newAdmin`,
    OTP: `/OTP`,
    TWO_FA_OTP: `/twoFaOtp`,
    CONFIRM_EMAIL: `/confirm-email/:id`,
    RESET_PASSWORD: `/reset-password`,
    DELETE_FORM: `/delete-form`,
    CONFIRM_PASSWORD: `/confirm-password`,
    RESET_SUCCESS: `/reset-success`,
};

export const ADMIN_ROUTE_PATHS = {
    ADMIN_LAYOUT: `${PREFIX}/admin`,
    ADMIN_TEST_LAYOUT: `${PREFIX}/adminTest`,
    INDEX: `/index`,
    INTEGRATION: `/integrations`,
    EDIT_PROFILE: `/edit-profile`,
    EMPLOYEE_INVITE: `/employee-invite`,
    FORWARDS_PAGE: `/forwards-page`,
    AUDIT: `/audit`,
    SUBSCRIPTION: `/subscription`,
    LOG_MANAGEMENT: `/logManagement`,
    PAYMENTS: `/payments`,
    CHECKOUT_SUCCESS: `/checkout-success`,
    RESET_SUCCESS: `/reset-success`,
};

export const ADMIN_ROUTE_TITLE_NAME = {
    LOGIN: "Login",
    REGISTER: "Register",
    DASHBOARD: "Dashboard",
    ADD_ADMIN: "ADD Admin",
    OTP: "OTP",
    TWO_FA_OTP: "TwoFactorAuth",
    CONFIRM_EMAIL: "Confirm Email",
    RESET_PASSWORD_CONFIRMED: "Password Reset Confirmed",
    RESET_PASSWORD: "Reset PASSOWRD",
    DELETE_FORM: "delete form",
    CONFIRM_PASSWORD: "Confirm Password",
    RESET_SUCCESS: "Reset Success",
    INDEX: "Dashboard",
    INTEGRATION: "App Settings",
    EDIT_PROFILE: "My Profile",
    EMPLOYEE_INVITE: "Employee Invites",
    FORWARDS_PAGE: "Track Forwards",
    AUDIT: "Audit",
    SUBSCRIPTION: "Subscriptions",
    AUDIT_SESSIONS: "Audit & Sessions",
    CREDITS_PAYMENTS: "Credits & Payments",
    LOG_MANAGEMENT: "Log Management",
    PAYMENTS: "Payments",
    CHECKOUT_SUCCESS: "CheckoutSuccess",

};