import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Headers/Header";
import Graph from "react-graph-vis";
import "../../assets/css/reactCalendar.css";
import SingleCalendar from "react-single-calendar";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button,
  Dropdown,
  Table,
  Row,
  Card,
  CardHeader,
} from "reactstrap";
import { forwards, messageChain, getIDS } from "../../network/ApiAxios";
import "../../assets/css/network.css";
import Tooltip from "react-bootstrap/Tooltip";
import { AiFillCalendar, AiFillFile } from "react-icons/ai";
import { HiOutlineViewList } from "react-icons/hi";
import { GrSort } from "react-icons/gr";
import { MdCode, MdOutlinePreview } from "react-icons/md";
import { TiFlowSwitch } from "react-icons/ti";
import { IoTimeSharp } from "react-icons/io5";
import { RiBusFill, RiContactsFill } from "react-icons/ri";
import { OverlayTrigger } from "react-bootstrap";
import Calendar from "react-calendar";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";
import { getForwardsDataAction } from "components/redux/adminActions";
import ReactPaginate from "react-paginate";
import useClickOutside from "functions/clickOutside";

function randomColor() {
  const red = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, "0");
  const green = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, "0");
  const blue = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, "0");
  return `#${red}${green}${blue}`;
}

const ForwardsPage = () => {
  // const resp = useSelector((state) => state.getForwardsDataReducer.forwards);
  // const loading = useSelector((state) => state.getForwardsDataReducer.loading);
  // console.log(data,'bb');
  const { data } = useSelector((state) => state.getDataReducer);
  const [search, setSearch] = useState("");
  const [searchList, setSearchList] = useState("");
  const [searchListArray, setSearchListArray] = useState([]);
  const [filteredDatesListArray, setFilteredDatesListArray] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const graphContainerRef = useRef(null);
  const calendarContainerRef = useRef(null);

  // const dispatch = useDispatch();
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(true);
  const [filename, setFilename] = useState("");
  const [ownerName, setOwnerName] = useState("");
  // const [filteredData, setFilteredData] = useState("");
  const [graph, setGraph] = useState({
    nodes: [],
    edges: [],
  });
  const [calendar, setCalendar] = useState(false);
  const [date, filterDate] = useState(new Date());
  const orgToken = JSON.parse(localStorage.getItem("user")).orgToken;
  const [forwardDate, setForwardDate] = useState("");

  const [responseState, setResponse] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [listView, setListView] = useState(false);
  const [graphView, setGraphView] = useState(true);
  const [nestedFunc, setNestedFunc] = useState(false);
  const [lsView, setLsView] = useState([]);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [isChain, setisChain] = useState(false);
  const [Sender, setSender] = useState("");
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     // Check if the click target is not a dot
  //     if (!event.target.classList.contains("dot-class")) {
  //       // Do nothing or perform your desired action here
  //       console.log("Clicked outside the dots");
  //     }
  //   };

  //   // Attach the event listener when the component mounts
  //   document.addEventListener("click", handleClickOutside);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, []);
  useClickOutside(calendarContainerRef, () => {
    setCalendar(false);
  });
  const options = {
    edges: {
      color: "#000000",
      smooth: {
        type: "discrete",
        roundness: 0.4,
      },
    },
    height: "700px",
    interaction: { hover: true },
  };
  const getNode = (id) => {
    return graph.nodes.filter((node) => node.id == id);
  };
  const events = {
    showPopup: async (event) => {
      console.log(event);
      const node = getNode(event);
    },

    select: async ({ nodes }) => {
      console.log(nodes, "qwww");
      if (nodes.length > 0) {
        if (!isChain) {
          var node = getNode(nodes[0]);
          console.log(node[0]);
          const { data } = await messageChain(
            nodes[0],
            node[0]?.orignal_sender
          );
          console.log(data, "heyaa");
          if (data.success) {
            var presence_node = [];
            var intnodes = [];
            var intedges = [];
            var respNodes = data.forwards[0]?.forward_details;
            var timeS = data.forwards[0]?.forward_details[0]?.timestamp;
            setTime(timeS);
            console.log(timeS, "timeStamp");
            var orignal_sender = data.forwards[0]?.orignal_sender;
            intnodes.push({
              id: orignal_sender,
              color: randomColor(),
              shape: "hexagon",
              title: orignal_sender,
            });
            if (respNodes.length > 0) {
              const node1 = respNodes[0]?.sender_id;
              intedges.push({ from: orignal_sender, to: node1 });
              presence_node.push(orignal_sender);
            }

            setSender(data?.user);
            for (var node in respNodes) {
              const node1 = respNodes[node]?.sender_id;
              const node2 = respNodes[node]?.receiver_id;

              if (!presence_node.includes(node1)) {
                presence_node.push(node1);

                intnodes.push({
                  id: node1,
                  color: randomColor(),
                  shape: node === "0" ? "hexagon" : "dot",
                  title: node1,
                });
              }
              if (!presence_node.includes(node2)) {
                presence_node.push(node2);
                intnodes.push({
                  id: node2,
                  color: randomColor(),
                  title: node2,
                });
              }
              intedges.push({ from: node1, to: node2 });
            }

            const res = await getIDS(presence_node);
            console.log(res.data);
            if (res.data.success) {
              for (nodes in intnodes) {
                intnodes[nodes].title = data?.forwards[0]?.forward_details[0]
                  ?.isGroup
                  ? "Group Message " +
                  res.data.user[nodes] +
                  " File Name : " +
                  data.forwards[0]?.filename
                  : "Personal Chat " +
                  res.data.user[nodes] +
                  " File Name : " +
                  data.forwards[0]?.filename;
                setFilename(data.forwards[0]?.filename);
                setOwnerName(res.data.user[nodes]);
              }
            }

            setGraph({
              nodes: intnodes,
              edges: intedges,
            });
            setisChain(!isChain);
          }
        } else {
          const { data } = responseState;
          var intnodes = data?.forwards?.filter(
            (message) => message.forward_message_ID != "master"
          );
          setLsView(intnodes);
          intnodes = intnodes?.map((message) => {
            return {
              id: message.forward_message_ID,
              color: randomColor(),
              value: message.forward_count,
              title:
                " File Name :" +
                message.filename +
                " Original Sender : " +
                " " +
                message.original_sender_name,
              shape: "dot",
              orignal_sender: message.orignal_sender,
            };
          });
          setGraph({
            nodes: intnodes,
          });
          setisChain(!isChain);
        }
      }
    },
  };
  function preTitle(text) {
    const container = document.createElement("title");
    container.innerText = text;
    return container;
  }
  useEffect(() => {
    const runAsync = async () => {
      const orgToken = JSON.parse(localStorage.getItem("user")).orgToken;
      setLoading(true);
      const response = await forwards(orgToken);
      setLoading(false);
      setResponse(response);
      const { data } = response;

      // console.log(resp, "qqq");
      var intnodes = data?.forwards?.filter(
        (message) => message.forward_message_ID != "master"
      );
      setLsView(intnodes);
      intnodes = intnodes?.map((message) => {
        return {
          id: message.forward_message_ID,
          color: randomColor(),
          value: message.forward_count,
          title:
            " File Name :" +
            message.filename +
            " Original Sender : " +
            " " +
            message.original_sender_name,
          shape: "dot",
          orignal_sender: message.orignal_sender,
        };
      });
      setGraph({
        nodes: intnodes,
      });
    };
    console.log(graph, "vv");
    runAsync();
  }, []);

  useEffect(() => {
    if (responseState) {
      const { data } = responseState;
      // console.log(data, "qwqw");

      var intnodes = data?.forwards?.filter(
        (message) => message?.forward_message_ID !== "master"
      );
      setLsView(intnodes);
      intnodes = intnodes?.map((message) => {
        return {
          id: message.forward_message_ID,
          color: randomColor(),
          value: message.forward_count,
          title:
            " File Name :" +
            message.filename +
            " Original Sender : " +
            " " +
            message.original_sender_name,
          shape: "dot",
          orignal_sender: message.orignal_sender,
        };
      });
      if (graphView) {
        // setFilteredDatesListArray([]);
        if (search !== "" && search.length !== 0) {
          console.log(search, "search");
          intnodes = data?.forwards?.filter((message) =>
            message.filename.includes(search)
          );
          intnodes = intnodes?.map((message) => {
            return {
              id: message.forward_message_ID,
              color: randomColor(),
              value: message.forward_count,
              title:
                " File Name :" +
                message.filename +
                " Original Sender : " +
                " " +
                message.original_sender_name,
              shape: "dot",
              orignal_sender: message.orignal_sender,
            };
          });
        }

        setGraph({
          nodes: intnodes,
        });
      } else {
        var searchedFile = data?.forwards?.filter((message) =>
          message.filename.toUpperCase().includes(searchList.toUpperCase())
        );
        console.log(searchedFile, "ss");

        setSearchListArray(searchedFile);
      }
    } else {
      console.log("Empty response state ", responseState);
    }
  }, [search, responseState, searchList]);

  // console.log(dateFilter.startDate, dateFilter.endDate, "check");

  function renderDot(d) {
    const { data } = responseState;
    var dotShow = data?.forwards?.map((item) => {
      return {
        timeS: item.data?.map((it) => it.timestamp),
      };
    });

    // console.log(dotShow, "dotShow");
    const comparingDates = dotShow?.map((it) => {
      return {
        // it: new Date(moment(it, "YYYY-MM-DDTHH:mm:ss")),
        it: new Date(moment(it.timeS, "YYYY-MM-DDTHH:mm:ss")),
      };
    });

    // const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

    const dottedDates = comparingDates.map((it) => {
      if (
        new Date(it.it).setHours(0, 0, 0, 0) == new Date(d).setHours(0, 0, 0, 0)
      ) {
        return <span className="dot" style={{ backgroundColor: "#3107cb" }} />;
      }
      return null;
    });
    return dottedDates;
  }

  const filterDataBasedOnDates = () => {
    if (graphView === true) {
      setCalendar(false);
      const { data } = responseState;

      // console.log(date[0], date[1], "St&EndDate");
      var filteringDates = data?.forwards?.map((item) => {
        return {
          msgId: item,
          timeS: item.data?.map((it) => it.timestamp),
        };
      });

      console.log(filteringDates, "ree");
      const comparingDates = filteringDates?.map((it) => {
        return {
          // it: new Date(moment(it, "YYYY-MM-DDTHH:mm:ss")),
          msgId: it.msgId,
          it: new Date(moment(it.timeS, "YYYY-MM-DDTHH:mm:ss")),
        };
      });

      const startDate = new Date(moment(date[0], "MMMM-DD-YYYYTHH:mm:ss"));
      const endDate = new Date(moment(date[1], "MMMM-DD-YYYYTHH:mm:ss"));
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(24, 0, 0, 0);

      data?.forwards?.filter((row) => {
        console.log(startDate, "startDate");
        console.log(endDate, "endDate");

        var filteredDatesData = comparingDates
          ?.filter((i) => i.it >= startDate && i.it <= endDate)
          ?.map((message) => {
            return {
              id: message.msgId.forward_message_ID,
              color: randomColor(),
              value: message.msgId.forward_count,
              title:
                " File Name :" +
                message.msgId.filename +
                " Original Sender : " +
                " " +
                message.msgId.original_sender_name,
              shape: "dot",
              orignal_sender: message.msgId.orignal_sender,
            };
          });
        console.log(filteredDatesData, "transformed comp1");
        setGraph({
          nodes: filteredDatesData,
        });
      });
      filterDate("");
      setFilteredDatesListArray([]);
    } else {
      setCalendar(false);
      setSearchList("");
      setSearchListArray([]);
      setFilteredDatesListArray([]);
      const { data } = responseState;

      // console.log(date[0], date[1], "St&EndDate");
      var filteringDates = data?.forwards?.map((item) => {
        return {
          msgId: item,
          timeS: item.data?.map((it) => it.timestamp),
        };
      });

      console.log(filteringDates, "ree");
      const comparingDates = filteringDates?.map((it) => {
        return {
          // it: new Date(moment(it, "YYYY-MM-DDTHH:mm:ss")),
          msgId: it.msgId,
          it: new Date(moment(it.timeS, "YYYY-MM-DDTHH:mm:ss")),
        };
      });

      const startDate = new Date(moment(date[0], "MMMM-DD-YYYYTHH:mm:ss"));
      const endDate = new Date(moment(date[1], "MMMM-DD-YYYYTHH:mm:ss"));
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(24, 0, 0, 0);

      data?.forwards?.filter((row) => {
        console.log(startDate, "startDate");
        console.log(endDate, "endDate");

        var filteredDatesListViewData = comparingDates?.filter(
          (i) => i.it >= startDate && i.it <= endDate
        );
        console.log(filteredDatesListViewData, "helo");
        setFilteredDatesListArray(filteredDatesListViewData);
      });
      filterDate("");
    }
    // console.log(intnodes, "hiii");
  };
  // console.log(graphView, "load");
  // console.log(listView, "load2");
  // console.log(graph.nodes, "hey");

  const nestedData = async (forwardId, ogSender) => {
    console.log(forwardId, ogSender, "og");
    setGraphView(false);
    if (!isChain) {
      setGraphView(false);
      setNestedFunc(true);
      setListView(false);
      const { data } = await messageChain(forwardId, ogSender);
      // console.log(data, "heyaa");
      if (data.success) {
        setGraphView(true);
        var presence_node = [];
        var intnodes = [];
        var intedges = [];
        var respNodes = data.forwards[0]?.forward_details;
        var timeS = data.forwards[0]?.forward_details[0]?.timestamp;
        setTime(timeS);
        console.log(timeS, "timeStamp");
        setSender(data?.user);
        console.log("hello sagar", data);

        var orignal_sender = data.forwards[0]?.orignal_sender;
        intnodes.push({
          id: orignal_sender,
          color: randomColor(),
          shape: "hexagon",
          title: orignal_sender,
        });
        if (respNodes.length > 0) {
          const node1 = respNodes[0]?.sender_id;
          intedges.push({ from: orignal_sender, to: node1 });
          presence_node.push(orignal_sender);
        }


        for (var node in respNodes) {
          const node1 = respNodes[node]?.sender_id;
          const node2 = respNodes[node]?.receiver_id;

          if (!presence_node.includes(node1)) {
            presence_node.push(node1);
            console.log(presence_node, "presence");
            intnodes.push({
              id: node1,
              color: randomColor(),
              shape: node === "0" ? "hexagon" : "dot",
              title: node1,
            });
          }
          if (!presence_node.includes(node2)) {
            presence_node.push(node2);
            intnodes.push({ id: node2, color: randomColor(), title: node2 });
          }
          intedges.push({ from: node1, to: node2 });
        }

        setGraphView(false);
        const res = await getIDS(presence_node);
        console.log(res.data);
        if (res.data.success) {
          setGraphView(true);
          for (forwardId in intnodes) {
            console.log(intnodes, "qww1");
            intnodes[forwardId].title = data?.forwards[0]?.forward_details[0]
              ?.isGroup
              ? "Group Message " +
              res.data.user[forwardId] +
              " File Name : " +
              data.forwards[0]?.filename
              : "Personal Chat " +
              res.data.user[forwardId] +
              " File Name : " +
              data.forwards[0]?.filename;
            setFilename(data.forwards[0]?.filename);
            setOwnerName(res.data.user[forwardId]);
          }
        }

        setGraph({
          nodes: intnodes,
          edges: intedges,
        });
        setisChain(!isChain);
      }
    } else {
      console.log("Modi here");
      const { data } = responseState;
      var intnodes = data?.forwards?.filter(
        (message) => message.forward_message_ID != "master"
      );
      // setLsView(intnodes);
      intnodes = intnodes?.map((message) => {
        console.log(message, "qww");
        return {
          id: message.forward_message_ID,
          color: randomColor(),
          value: message.forward_count,
          title:
            " File Name :" +
            message.filename +
            " Original Sender : " +
            " " +
            message.original_sender_name,
          shape: "dot",
          orignal_sender: message.orignal_sender,
        };
      });
      setGraph({
        nodes: intnodes,
      });
      setisChain(!isChain);
    }
  };

  // console.log(lsView, "lss");

  function tConvert(time) {
    // Check correct time format and split into
    const c = time?.split(".")[0]?.split(":");
    const newTimeString = c && `${c[0]}:${c[1]}`;
    time = newTimeString
      ?.toString()
      ?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time?.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time?.join(""); // return adjusted time or original string
  }

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrPage(selectedPage);
    setSearchListArray([]);
    setSearchList("");
  };

  // console.log(lsView.length, "hi");

  const PER_PAGE = 10;
  const offset = currPage * PER_PAGE;
  const pageCount =
    searchListArray.length > 0
      ? Math.ceil(searchListArray.length / PER_PAGE)
      : filteredDatesListArray.length > 0
        ? Math.ceil(filteredDatesListArray.length / PER_PAGE)
        : Math.ceil(lsView.length / PER_PAGE);

  // console.log(pageCount, "pc");

  const currentPageData = lsView.slice(offset, offset + PER_PAGE).map((g) => (
    <tr key={g.data.map((it) => it._id)}>
      <td
        style={{
          color: "black",
        }}
      >
        {g.filename && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="">{g.filename}</Tooltip>}
          >
            <h6>{g.filename.trim().substring(0, 20) + "..."}</h6>
          </OverlayTrigger>
        )}
      </td>

      <td
        style={{
          color: "black",
        }}
      >
        {g.original_sender_name}
      </td>

      <td
        style={{
          color: "black",
        }}
      >
        <Button
          color="primary"
          size="sm"
          style={{
            borderRadius: "2rem",
          }}
          onClick={() => {
            setNestedFunc(true);
            nestedData(g.forward_message_ID, g.orignal_sender);
          }}
        >
          Nested
        </Button>
      </td>

      <td
        style={{
          color: "black",
        }}
      >
        {g?.data[0]?.timestamp.split("T")[0].split("-").reverse().join("/") +
          " | " +
          tConvert(g.data[0]?.timestamp.split("T")[1])}
      </td>
    </tr>
  ));

  const showFilteredData = filteredDatesListArray
    ?.slice(offset, offset + PER_PAGE)
    ?.map((g) => (
      <tr key={g.msgId.data.map((it) => it._id)}>
        <td
          style={{
            color: "black",
          }}
        >
          {g.msgId.filename && (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="">{g.msgId.filename}</Tooltip>}
            >
              <h6>{g.msgId.filename.trim().substring(0, 20) + "..."}</h6>
            </OverlayTrigger>
          )}
        </td>
        <td
          style={{
            color: "black",
          }}
        >
          {g.msgId.original_sender_name}
        </td>
        <td
          style={{
            color: "black",
          }}
        >
          <Button
            color="primary"
            size="sm"
            style={{
              borderRadius: "2rem",
            }}
            onClick={() => {
              setNestedFunc(true);
              nestedData(g.msgId.forward_message_ID, g.msgId.orignal_sender);
            }}
          >
            Nested
          </Button>
        </td>
        <td
          style={{
            color: "black",
          }}
        >
          {g?.msgId?.data[0]?.timestamp
            .split("T")[0]
            .split("-")
            .reverse()
            .join("/") +
            " | " +
            tConvert(g?.msgId?.data[0]?.timestamp.split("T")[1])}
        </td>
      </tr>
    ));

  const showSearchedData = searchListArray
    ?.slice(offset, offset + PER_PAGE)
    ?.map((g) => (
      <tr key={g.data.map((it) => it._id)}>
        <td
          style={{
            color: "black",
          }}
        >
          {g.filename && (
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="">{g.filename}</Tooltip>}
            >
              <h6>{g.filename.trim().substring(0, 20) + "..."}</h6>
            </OverlayTrigger>
          )}
        </td>

        <td
          style={{
            color: "black",
          }}
        >
          {g.original_sender_name}
        </td>

        <td
          style={{
            color: "black",
          }}
        >
          <Button
            color="primary"
            size="sm"
            style={{
              borderRadius: "2rem",
            }}
            onClick={() => {
              setNestedFunc(true);
              nestedData(g.forward_message_ID, g.orignal_sender);
            }}
          >
            Nested
          </Button>
        </td>
        <td
          style={{
            color: "black",
          }}
        >
          {g?.data[0]?.timestamp.split("T")[0].split("-").reverse().join("/") +
            " | " +
            tConvert(g.data[0]?.timestamp.split("T")[1])}
        </td>
      </tr>
    ));

  console.log(calendar, "cc");
  return (
    <>
      <Header />

      <div
        style={{
          float: "right",
          marginTop: "20px",
        }}
      >
        <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
          <FormGroup className="mb-0">
            <InputGroup className="input-group-alternative mr-3">
              <div className="input-group">
                <InputGroupText>
                  <i
                    className="fas fa-search"
                    style={{
                      color: "black",
                    }}
                  />
                </InputGroupText>
              </div>
              <input
                type="text"
                placeholder="Search For Files....."
                value={graphView ? search : searchList}
                onChange={(e) => {
                  if (graphView) {
                    setSearch(e.target.value);
                  } else {
                    setSearchList(e.target.value);
                  }
                }}
              />
            </InputGroup>
          </FormGroup>
          <div ref={calendarContainerRef}>
            <div className="mr-2">
              <Button
                color="primary"
                size="sm"
                style={{ borderRadius: "2rem", marginTop: "-13px" }}
                onClick={() => {
                  setCalendar((prev) => !prev);
                }}
                disabled={loading}
              >
                <AiFillCalendar
                  color="#ffffff"
                  fill="white"
                  size={20}
                  className="sortIcon"
                />
              </Button>
              {calendar === true && (
                <Calendar
                  onChange={filterDate}
                  value={date}
                  selectRange={true}
                  className="dateContainer"
                  tileContent={({ date }) => renderDot(date)}
                />
              )}
              {calendar === true && (
                <Button
                  onClick={filterDataBasedOnDates}
                  color="primary"
                  size="sm"
                  style={{
                    borderRadius: "2rem",
                    position: "absolute",
                    marginTop: "357px",
                    marginLeft: "-62px",
                    zIndex: "9999",
                  }}
                >
                  Filter
                </Button>
              )}
            </div>
          </div>
          <div className="d-flex" style={{ marginTop: "-13px" }}>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} disabled={loading}>
              <DropdownToggle
                // caret
                size="sm"
                color="primary"
                style={{ borderRadius: "2rem" }}
              >
                {/* Visualization */}
                <TiFlowSwitch size={20} />
              </DropdownToggle>
              {/* <Button color='primary' size='sm' style={{ borderRadius: "2rem" }}>
						</Button> */}
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setListView(true);
                    setGraphView(false);
                    setSearchList("");
                    filterDate("");
                    setFilteredDatesListArray([]);
                  }}
                >
                  List View
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setGraphView(true);
                    setListView(false);
                    setSearchList("");
                    filterDate("");
                    setFilteredDatesListArray([]);
                  }}
                >
                  Graph View
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Form>
      </div>

      <div className="forwardCard justify-content-center">
        <div
          style={{
            alignItems: "center",
          }}
        >
          {!isChain ? (
            <>
              <h1
                style={{
                  textAlign: "center",
                  fontSize: "30px",
                  color: "black",
                  marginTop: "10px",
                }}
              >
                Forward Details Page
              </h1>
              <p
                style={{
                  textAlign: "center",
                  color: "black",
                  marginTop: "20px",
                }}
              >
                You can see all the messages being forwarded from your
                organization
              </p>
              <p
                style={{
                  textAlign: "center",
                  color: "black",
                  marginTop: "20px",
                }}
              >
                For Tracking each message click on the respective node
              </p>
            </>
          ) : (
            Sender && (
              <>
                <h5
                  style={{
                    textAlign: "center",
                    fontSize: "30px",
                    color: "black",
                    marginTop: "4px",
                  }}
                >
                  Forward Details Message Tracking Page
                </h5>

                <div
                  className="d-flex align-items-center justify-content-around rounded-md text-black cardShadow p-4 m-4"
                  style={{ backgroundColor: "#F7F7FA" }}
                >
                  <div className="d-flex flex-col">
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <AiFillFile />
                      </div>
                      <h6>File Name</h6>
                    </div>
                    <div className="ml-4">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="button-tooltip-2">{filename}</Tooltip>
                        }
                      >
                        <p>{filename.trim().substring(0, 20) + "..."}</p>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="d-flex flex-col">
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <RiContactsFill />
                      </div>
                      <h6>File Owner</h6>
                    </div>
                    <div className="ml-4">
                      <p>{Sender}</p>
                    </div>
                  </div>
                  <div className="d-flex flex-col">
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <IoTimeSharp />
                      </div>
                      <h6>Time Stamp</h6>
                    </div>
                    <div className="ml-4">
                      <p>
                        {time?.split("T")[0]?.split("-")?.reverse()?.join("/") +
                          " | " +
                          tConvert(time?.split("T")[1])}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </div>
      {loading === true ? (
        <div
          className="d-flex align-items-center justify-content-center mt-4"
          style={{ marginBottom: "265px" }}
        >
          <RotatingLines
            strokeColor="black"
            strokeWidth="5"
            animationDuration="0.75"
            width="50"
            height="50"
            visible={true}
          />
        </div>
      ) : data?.forwards === 0 ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ color: "grey", marginBottom: "265px" }}
        >
          No forwards to show
        </div>
      ) : (
        <div style={{ margin: "22px" }}>
          <Row>
            <div className="col">
              {listView === true && (
                <Card className="shadow">
                  <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                    <h5 className="mb-0 text-black ">Forwards</h5>
                  </CardHeader>

                  <Table
                    style={{ display: listView ? "" : "none" }}
                    className="align-items-center table-flush"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">File Name</th>
                        <th scope="col">Original Sender Name</th>
                        <th scope="col">Nested Data</th>
                        <th scope="col">TimeStamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* {!searchList.length &&
                        !filteredDatesListArray.length &&
                        currentPageData} */}
                      {searchList.length === 0 &&
                        filteredDatesListArray.length === 0 ? (
                        currentPageData
                      ) : searchListArray.length > 0 &&
                        filteredDatesListArray.length === 0 ? (
                        showSearchedData
                      ) : searchListArray.length === 0 &&
                        filteredDatesListArray.length > 0 ? (
                        showFilteredData
                      ) : filteredDatesListArray.length === 0 ? (
                        <p
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          No data found
                        </p>
                      ) : (
                        searchListArray.length === 0 && (
                          <p
                            style={{
                              color: "red",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            No data found
                          </p>
                        )
                      )}
                    </tbody>
                  </Table>
                </Card>
              )}
              <div
                style={{ display: graphView ? "" : "none" }}
                ref={graphContainerRef}
              >
                <Graph graph={graph} options={options} events={events} />
              </div>
            </div>
          </Row>
          {lsView.length > 9 && listView && (
            <div className="d-flex align-items-center justify-content-center mt-2">
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          )}
        </div>
      )}
      {/* <div className="card-title justify-content-center">
        {isChain ? "Originally sent by " + Sender : "Forwards"}
      </div> */}
    </>
  );
};

export default ForwardsPage;
