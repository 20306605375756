import Header from "components/Headers/Header";
import React from "react";
import { Card } from "reactstrap";
import checkoutSuccess from "../../assets/CheckoutSuccess.svg";
import { useEffect } from "react";
import { useState } from "react";
import { MdSimCardDownload } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import customToast from "./ToastComponent";
import { logout } from "network/ApiAxios";
import { useHistory } from "react-router/cjs/react-router.min";
import { AUTH_ROUTE_PATHS } from "routes-constants";

const CheckoutSuccess = (props) => {
  const planSelected = JSON.parse(localStorage.getItem("planSelected"));
  const totalUsers = JSON.parse(localStorage.getItem("totalUsers"));
  const user = JSON.parse(localStorage.getItem("user"));
  const orgDetails = JSON.parse(localStorage.getItem("keys"));
  const [formattedDate, setFormattedDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  // const history = useHistory();

  useEffect(() => {
    function getFormattedDate(date) {
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();

      return `${day}${getOrdinalIndicator(day)} ${month} ${year}`;
    }

    // Function to get the ordinal indicator (st, nd, rd, or th) for a given day
    function getOrdinalIndicator(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      }
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    }

    const today = new Date();
    const formattedDate = getFormattedDate(today);
    setFormattedDate(formattedDate);

    // Add 30 days to the current date
    // if (orgDetails.expiry_date < today) {
    //   const thirtyDaysLater = new Date(today);
    //   thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);
    //   const formattedDate30DaysLater = getFormattedDate(thirtyDaysLater);
    //   setExpiryDate(formattedDate30DaysLater);
    //   console.log(formattedDate30DaysLater);
    // } else {
    //   const thirtyDaysLater = new Date(orgDetails.expiry_date);
    //   thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);
    //   const formattedDate30DaysLater = getFormattedDate(thirtyDaysLater);
    //   setExpiryDate(formattedDate30DaysLater);
    //   console.log(formattedDate30DaysLater);
    // }

    const expiryDate = new Date(orgDetails.expiry_date);
    // console.log(expiryDate, "db expiry date");

    if (expiryDate < today) {
      // If the expiry date is in the past, add 30 days to the current date
      const thirtyDaysLater = new Date(today);
      thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);
      const formattedDate30DaysLater = getFormattedDate(thirtyDaysLater);
      setExpiryDate(formattedDate30DaysLater);
      // console.log(formattedDate30DaysLater, "if logic");
    } else {
      // If the expiry date is in the future, keep the existing expiry date
      const formattedExpiryDate = getFormattedDate(expiryDate);
      setExpiryDate(formattedExpiryDate);
      // console.log(formattedExpiryDate, "formattedExpiry date  else");
    }

    customToast(
      true,
      "",
      "",
      "Log out",
      "You will be logged out automatically after 8 seconds.",
      "Subscription Purchased",
      ""
    );
    const f = async () => {
      await logout(user.orgToken);
      localStorage.clear();
      props.history.push(`${AUTH_ROUTE_PATHS.AUTH_LAYOUT}${AUTH_ROUTE_PATHS.LOGIN}`);
    };
    setTimeout(() => {
      f();
    }, 8000);
  }, []);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <div className="d-flex align-items-center justify-content-center">
        <div
          className=" text-black mt-4 ml-4 mr-4 d-flex"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px 2.6px",
            borderRadius: "15px",
            backgroundColor: "#FFF",
            marginBottom: "100px",
            width: "65%",
          }}
        >
          <div className="d-flex flex-col pt-4 pl-4">
            <p
              className="mb-2"
              style={{ color: "#41414180", fontSize: "18px" }}
            >
              Transaction Successful
            </p>
            <h1 style={{ color: "#47BE9B", fontSize: "24px" }}>
              Your current subscription: <b>{planSelected}</b>
            </h1>
            <p
              className="mb-2"
              style={{ fontSize: "18px", color: "#41414180" }}
            >
              Experience the utmost control over your enterprise data
            </p>
            <p className="text-black font-bold mb-2">
              Your subscription details:
            </p>

            <div style={{ color: "#414141C9" }} className="mb-4">
              <p className="mb-1">
                <b>Date of subscription:</b> {formattedDate}
              </p>
              <p className="mb-1">
                <b>New User Limit:</b> {totalUsers} users
              </p>
              <p className="mb-1">
                <b>Next Billing Date:</b> {expiryDate}
              </p>
            </div>

            <div className="d-flex" style={{ color: "#414141C9" }}>
              <MdSimCardDownload
                style={{
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
              />
              <h1>Download invoice</h1>
            </div>
          </div>

          <img
            src={checkoutSuccess}
            className="w-50 h-50"
            style={{ objectFit: "cover" }}
          />
          {/* <div></div> */}
        </div>
      </div>
    </>
  );
};

export default CheckoutSuccess;
